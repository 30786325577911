@font-face {
  font-family: "remixicon";
  src: url('../fonts/remixicon.eot?t=1551008796529'); /* IE9*/
  src: url('../fonts/remixicon.eot?t=1551008796529#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("../fonts/remixicon.woff2?t=1551008796529") format("woff2"),
  url("../fonts/remixicon.woff?t=1551008796529") format("woff"),
  url('../fonts/remixicon.ttf?t=1551008796529') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('../fonts/remixicon.svg?t=1551008796529#remixicon') format('svg'); /* iOS 4.1- */
}

[class^="remixicon-"], [class*=" remixicon-"] {
  font-family: 'remixicon' !important;
  font-size: inherit;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.remixicon-account-box-fill:before { content: "\ef35"; }
.remixicon-account-box-line:before { content: "\ef36"; }
.remixicon-account-circle-fill:before { content: "\ef37"; }
.remixicon-account-circle-line:before { content: "\ef38"; }
.remixicon-account-pin-box-fill:before { content: "\ef39"; }
.remixicon-account-pin-box-line:before { content: "\ef3a"; }
.remixicon-account-pin-circle-fill:before { content: "\ef3b"; }
.remixicon-account-pin-circle-line:before { content: "\ef3c"; }
.remixicon-add-box-fill:before { content: "\ef3d"; }
.remixicon-add-box-line:before { content: "\ef3e"; }
.remixicon-add-circle-fill:before { content: "\ef3f"; }
.remixicon-add-circle-line:before { content: "\ef40"; }
.remixicon-add-fill:before { content: "\ef41"; }
.remixicon-add-line:before { content: "\ef42"; }
.remixicon-admin-fill:before { content: "\ef43"; }
.remixicon-admin-line:before { content: "\ef44"; }
.remixicon-airplay-fill:before { content: "\ef45"; }
.remixicon-airplay-line:before { content: "\ef46"; }
.remixicon-alarm-fill:before { content: "\ef47"; }
.remixicon-alarm-line:before { content: "\ef48"; }
.remixicon-alarm-warning-fill:before { content: "\ef49"; }
.remixicon-alarm-warning-line:before { content: "\ef4a"; }
.remixicon-album-fill:before { content: "\ef4b"; }
.remixicon-album-line:before { content: "\ef4c"; }
.remixicon-align-bottom:before { content: "\ef4d"; }
.remixicon-align-center:before { content: "\ef4e"; }
.remixicon-align-justify:before { content: "\ef4f"; }
.remixicon-align-left:before { content: "\ef50"; }
.remixicon-align-right:before { content: "\ef51"; }
.remixicon-align-top:before { content: "\ef52"; }
.remixicon-align-vertically:before { content: "\ef53"; }
.remixicon-alipay-fill:before { content: "\ef54"; }
.remixicon-alipay-line:before { content: "\ef55"; }
.remixicon-amazon-fill:before { content: "\ef56"; }
.remixicon-amazon-line:before { content: "\ef57"; }
.remixicon-anchor-fill:before { content: "\ef58"; }
.remixicon-anchor-line:before { content: "\ef59"; }
.remixicon-android-fill:before { content: "\ef5a"; }
.remixicon-android-line:before { content: "\ef5b"; }
.remixicon-apple-fill:before { content: "\ef5c"; }
.remixicon-apple-line:before { content: "\ef5d"; }
.remixicon-apps-2-fill:before { content: "\ef5e"; }
.remixicon-apps-2-line:before { content: "\ef5f"; }
.remixicon-apps-fill:before { content: "\ef60"; }
.remixicon-apps-line:before { content: "\ef61"; }
.remixicon-archive-drawer-fill:before { content: "\ef62"; }
.remixicon-archive-drawer-line:before { content: "\ef63"; }
.remixicon-archive-fill:before { content: "\ef64"; }
.remixicon-archive-line:before { content: "\ef65"; }
.remixicon-arrow-down-circle-fill:before { content: "\ef66"; }
.remixicon-arrow-down-circle-line:before { content: "\ef67"; }
.remixicon-arrow-down-fill:before { content: "\ef68"; }
.remixicon-arrow-down-line:before { content: "\ef69"; }
.remixicon-arrow-down-s-fill:before { content: "\ef6a"; }
.remixicon-arrow-down-s-line:before { content: "\ef6b"; }
.remixicon-arrow-drop-down-fill:before { content: "\ef6c"; }
.remixicon-arrow-drop-down-line:before { content: "\ef6d"; }
.remixicon-arrow-drop-left-fill:before { content: "\ef6e"; }
.remixicon-arrow-drop-left-line:before { content: "\ef6f"; }
.remixicon-arrow-drop-right-fill:before { content: "\ef70"; }
.remixicon-arrow-drop-right-line:before { content: "\ef71"; }
.remixicon-arrow-drop-up-fill:before { content: "\ef72"; }
.remixicon-arrow-drop-up-line:before { content: "\ef73"; }
.remixicon-arrow-go-back-fill:before { content: "\ef74"; }
.remixicon-arrow-go-back-line:before { content: "\ef75"; }
.remixicon-arrow-go-forward-fill:before { content: "\ef76"; }
.remixicon-arrow-go-forward-line:before { content: "\ef77"; }
.remixicon-arrow-left-circle-fill:before { content: "\ef78"; }
.remixicon-arrow-left-circle-line:before { content: "\ef79"; }
.remixicon-arrow-left-down-fill:before { content: "\ef7a"; }
.remixicon-arrow-left-down-line:before { content: "\ef7b"; }
.remixicon-arrow-left-fill:before { content: "\ef7c"; }
.remixicon-arrow-left-line:before { content: "\ef7d"; }
.remixicon-arrow-left-s-fill:before { content: "\ef7e"; }
.remixicon-arrow-left-s-line:before { content: "\ef7f"; }
.remixicon-arrow-left-up-fill:before { content: "\ef80"; }
.remixicon-arrow-left-up-line:before { content: "\ef81"; }
.remixicon-arrow-right-circle-fill:before { content: "\ef82"; }
.remixicon-arrow-right-circle-line:before { content: "\ef83"; }
.remixicon-arrow-right-down-fill:before { content: "\ef84"; }
.remixicon-arrow-right-down-line:before { content: "\ef85"; }
.remixicon-arrow-right-fill:before { content: "\ef86"; }
.remixicon-arrow-right-line:before { content: "\ef87"; }
.remixicon-arrow-right-s-fill:before { content: "\ef88"; }
.remixicon-arrow-right-s-line:before { content: "\ef89"; }
.remixicon-arrow-right-up-fill:before { content: "\ef8a"; }
.remixicon-arrow-right-up-line:before { content: "\ef8b"; }
.remixicon-arrow-up-circle-fill:before { content: "\ef8c"; }
.remixicon-arrow-up-circle-line:before { content: "\ef8d"; }
.remixicon-arrow-up-fill:before { content: "\ef8e"; }
.remixicon-arrow-up-line:before { content: "\ef8f"; }
.remixicon-arrow-up-s-fill:before { content: "\ef90"; }
.remixicon-arrow-up-s-line:before { content: "\ef91"; }
.remixicon-artboard-2-fill:before { content: "\ef92"; }
.remixicon-artboard-2-line:before { content: "\ef93"; }
.remixicon-artboard-fill:before { content: "\ef94"; }
.remixicon-artboard-line:before { content: "\ef95"; }
.remixicon-article-fill:before { content: "\ef96"; }
.remixicon-article-line:before { content: "\ef97"; }
.remixicon-at-fill:before { content: "\ef98"; }
.remixicon-at-line:before { content: "\ef99"; }
.remixicon-attachment-fill:before { content: "\ef9a"; }
.remixicon-attachment-line:before { content: "\ef9b"; }
.remixicon-award-fill:before { content: "\ef9c"; }
.remixicon-award-line:before { content: "\ef9d"; }
.remixicon-baidu-fill:before { content: "\ef9e"; }
.remixicon-baidu-line:before { content: "\ef9f"; }
.remixicon-ball-pen-fill:before { content: "\efa0"; }
.remixicon-ball-pen-line:before { content: "\efa1"; }
.remixicon-bank-card-2-fill:before { content: "\efa2"; }
.remixicon-bank-card-2-line:before { content: "\efa3"; }
.remixicon-bank-card-fill:before { content: "\efa4"; }
.remixicon-bank-card-line:before { content: "\efa5"; }
.remixicon-bank-fill:before { content: "\efa6"; }
.remixicon-bank-line:before { content: "\efa7"; }
.remixicon-bar-chart-2-fill:before { content: "\efa8"; }
.remixicon-bar-chart-2-line:before { content: "\efa9"; }
.remixicon-bar-chart-box-fill:before { content: "\efaa"; }
.remixicon-bar-chart-box-line:before { content: "\efab"; }
.remixicon-bar-chart-fill:before { content: "\efac"; }
.remixicon-bar-chart-line:before { content: "\efad"; }
.remixicon-barcode-fill:before { content: "\efae"; }
.remixicon-barcode-line:before { content: "\efaf"; }
.remixicon-barricade-fill:before { content: "\efb0"; }
.remixicon-barricade-line:before { content: "\efb1"; }
.remixicon-base-station-fill:before { content: "\efb2"; }
.remixicon-base-station-line:before { content: "\efb3"; }
.remixicon-battery-2-charge-fill:before { content: "\efb4"; }
.remixicon-battery-2-charge-line:before { content: "\efb5"; }
.remixicon-battery-2-fill:before { content: "\efb6"; }
.remixicon-battery-2-line:before { content: "\efb7"; }
.remixicon-battery-charge-fill:before { content: "\efb8"; }
.remixicon-battery-charge-line:before { content: "\efb9"; }
.remixicon-battery-fill:before { content: "\efba"; }
.remixicon-battery-line:before { content: "\efbb"; }
.remixicon-battery-low-fill:before { content: "\efbc"; }
.remixicon-battery-low-line:before { content: "\efbd"; }
.remixicon-behance-fill:before { content: "\efbe"; }
.remixicon-behance-line:before { content: "\efbf"; }
.remixicon-bike-fill:before { content: "\efc0"; }
.remixicon-bike-line:before { content: "\efc1"; }
.remixicon-bill-fill:before { content: "\efc2"; }
.remixicon-bill-line:before { content: "\efc3"; }
.remixicon-bit-coin-fill:before { content: "\efc4"; }
.remixicon-bit-coin-line:before { content: "\efc5"; }
.remixicon-bluetooth-connect-fill:before { content: "\efc6"; }
.remixicon-bluetooth-connect-line:before { content: "\efc7"; }
.remixicon-bluetooth-fill:before { content: "\efc8"; }
.remixicon-bluetooth-line:before { content: "\efc9"; }
.remixicon-blur-off-fill:before { content: "\efca"; }
.remixicon-blur-off-line:before { content: "\efcb"; }
.remixicon-bold:before { content: "\efcc"; }
.remixicon-book-2-fill:before { content: "\efcd"; }
.remixicon-book-2-line:before { content: "\efce"; }
.remixicon-book-3-fill:before { content: "\efcf"; }
.remixicon-book-3-line:before { content: "\efd0"; }
.remixicon-book-fill:before { content: "\efd1"; }
.remixicon-book-line:before { content: "\efd2"; }
.remixicon-book-mark-fill:before { content: "\efd3"; }
.remixicon-book-mark-line:before { content: "\efd4"; }
.remixicon-book-open-fill:before { content: "\efd5"; }
.remixicon-book-open-line:before { content: "\efd6"; }
.remixicon-book-read-fill:before { content: "\efd7"; }
.remixicon-book-read-line:before { content: "\efd8"; }
.remixicon-bookmark-2-fill:before { content: "\efd9"; }
.remixicon-bookmark-2-line:before { content: "\efda"; }
.remixicon-bookmark-3-fill:before { content: "\efdb"; }
.remixicon-bookmark-3-line:before { content: "\efdc"; }
.remixicon-bookmark-fill:before { content: "\efdd"; }
.remixicon-bookmark-line:before { content: "\efde"; }
.remixicon-briefcase-2-fill:before { content: "\efdf"; }
.remixicon-briefcase-2-line:before { content: "\efe0"; }
.remixicon-briefcase-3-fill:before { content: "\efe1"; }
.remixicon-briefcase-3-line:before { content: "\efe2"; }
.remixicon-briefcase-4-fill:before { content: "\efe3"; }
.remixicon-briefcase-4-line:before { content: "\efe4"; }
.remixicon-briefcase-5-fill:before { content: "\efe5"; }
.remixicon-briefcase-5-line:before { content: "\efe6"; }
.remixicon-briefcase-fill:before { content: "\efe7"; }
.remixicon-briefcase-line:before { content: "\efe8"; }
.remixicon-broadcast-fill:before { content: "\efe9"; }
.remixicon-broadcast-line:before { content: "\efea"; }
.remixicon-brush-2-fill:before { content: "\efeb"; }
.remixicon-brush-2-line:before { content: "\efec"; }
.remixicon-brush-3-fill:before { content: "\efed"; }
.remixicon-brush-3-line:before { content: "\efee"; }
.remixicon-brush-4-fill:before { content: "\efef"; }
.remixicon-brush-4-line:before { content: "\eff0"; }
.remixicon-brush-fill:before { content: "\eff1"; }
.remixicon-brush-line:before { content: "\eff2"; }
.remixicon-bug-2-fill:before { content: "\eff3"; }
.remixicon-bug-2-line:before { content: "\eff4"; }
.remixicon-bug-fill:before { content: "\eff5"; }
.remixicon-bug-line:before { content: "\eff6"; }
.remixicon-building-2-fill:before { content: "\eff7"; }
.remixicon-building-2-line:before { content: "\eff8"; }
.remixicon-building-3-fill:before { content: "\eff9"; }
.remixicon-building-3-line:before { content: "\effa"; }
.remixicon-building-4-fill:before { content: "\effb"; }
.remixicon-building-4-line:before { content: "\effc"; }
.remixicon-building-fill:before { content: "\effd"; }
.remixicon-building-line:before { content: "\effe"; }
.remixicon-bus-2-fill:before { content: "\efff"; }
.remixicon-bus-2-line:before { content: "\f000"; }
.remixicon-bus-fill:before { content: "\f001"; }
.remixicon-bus-line:before { content: "\f002"; }
.remixicon-calculator-fill:before { content: "\f003"; }
.remixicon-calculator-line:before { content: "\f004"; }
.remixicon-calendar-2-fill:before { content: "\f005"; }
.remixicon-calendar-2-line:before { content: "\f006"; }
.remixicon-calendar-event-fill:before { content: "\f007"; }
.remixicon-calendar-event-line:before { content: "\f008"; }
.remixicon-calendar-fill:before { content: "\f009"; }
.remixicon-calendar-line:before { content: "\f00a"; }
.remixicon-calendar-todo-fill:before { content: "\f00b"; }
.remixicon-calendar-todo-line:before { content: "\f00c"; }
.remixicon-camera-2-fill:before { content: "\f00d"; }
.remixicon-camera-2-line:before { content: "\f00e"; }
.remixicon-camera-3-fill:before { content: "\f00f"; }
.remixicon-camera-3-line:before { content: "\f010"; }
.remixicon-camera-fill:before { content: "\f011"; }
.remixicon-camera-lens-fill:before { content: "\f012"; }
.remixicon-camera-lens-line:before { content: "\f013"; }
.remixicon-camera-line:before { content: "\f014"; }
.remixicon-camera-off-fill:before { content: "\f015"; }
.remixicon-camera-off-line:before { content: "\f016"; }
.remixicon-camera-switch-fill:before { content: "\f017"; }
.remixicon-camera-switch-line:before { content: "\f018"; }
.remixicon-car-fill:before { content: "\f019"; }
.remixicon-car-line:before { content: "\f01a"; }
.remixicon-car-washing-fill:before { content: "\f01b"; }
.remixicon-car-washing-line:before { content: "\f01c"; }
.remixicon-cast-fill:before { content: "\f01d"; }
.remixicon-cast-line:before { content: "\f01e"; }
.remixicon-cellphone-fill:before { content: "\f01f"; }
.remixicon-cellphone-line:before { content: "\f020"; }
.remixicon-charging-pile-fill:before { content: "\f021"; }
.remixicon-charging-pile-line:before { content: "\f022"; }
.remixicon-chat-1-fill:before { content: "\f023"; }
.remixicon-chat-1-line:before { content: "\f024"; }
.remixicon-chat-2-fill:before { content: "\f025"; }
.remixicon-chat-2-line:before { content: "\f026"; }
.remixicon-chat-3-fill:before { content: "\f027"; }
.remixicon-chat-3-line:before { content: "\f028"; }
.remixicon-chat-4-fill:before { content: "\f029"; }
.remixicon-chat-4-line:before { content: "\f02a"; }
.remixicon-chat-check-fill:before { content: "\f02b"; }
.remixicon-chat-check-line:before { content: "\f02c"; }
.remixicon-chat-delete-fill:before { content: "\f02d"; }
.remixicon-chat-delete-line:before { content: "\f02e"; }
.remixicon-chat-download-fill:before { content: "\f02f"; }
.remixicon-chat-download-line:before { content: "\f030"; }
.remixicon-chat-forward-fill:before { content: "\f031"; }
.remixicon-chat-forward-line:before { content: "\f032"; }
.remixicon-chat-new-fill:before { content: "\f033"; }
.remixicon-chat-new-line:before { content: "\f034"; }
.remixicon-chat-settings-fill:before { content: "\f035"; }
.remixicon-chat-settings-line:before { content: "\f036"; }
.remixicon-chat-smile-2-fill:before { content: "\f037"; }
.remixicon-chat-smile-2-line:before { content: "\f038"; }
.remixicon-chat-smile-3-fill:before { content: "\f039"; }
.remixicon-chat-smile-3-line:before { content: "\f03a"; }
.remixicon-chat-smile-fill:before { content: "\f03b"; }
.remixicon-chat-smile-line:before { content: "\f03c"; }
.remixicon-chat-upload-fill:before { content: "\f03d"; }
.remixicon-chat-upload-line:before { content: "\f03e"; }
.remixicon-check-fill:before { content: "\f03f"; }
.remixicon-check-line:before { content: "\f040"; }
.remixicon-checkbox-blank-circle-fill:before { content: "\f041"; }
.remixicon-checkbox-blank-circle-line:before { content: "\f042"; }
.remixicon-checkbox-blank-fill:before { content: "\f043"; }
.remixicon-checkbox-blank-line:before { content: "\f044"; }
.remixicon-checkbox-circle-fill:before { content: "\f045"; }
.remixicon-checkbox-circle-line:before { content: "\f046"; }
.remixicon-checkbox-fill:before { content: "\f047"; }
.remixicon-checkbox-indeterminate-fill:before { content: "\f048"; }
.remixicon-checkbox-indeterminate-line:before { content: "\f049"; }
.remixicon-checkbox-line:before { content: "\f04a"; }
.remixicon-china-railway-fill:before { content: "\f04b"; }
.remixicon-china-railway-line:before { content: "\f04c"; }
.remixicon-chrome-fill:before { content: "\f04d"; }
.remixicon-chrome-line:before { content: "\f04e"; }
.remixicon-clapperboard-fill:before { content: "\f04f"; }
.remixicon-clapperboard-line:before { content: "\f050"; }
.remixicon-clipboard-fill:before { content: "\f051"; }
.remixicon-clipboard-line:before { content: "\f052"; }
.remixicon-close-circle-fill:before { content: "\f053"; }
.remixicon-close-circle-line:before { content: "\f054"; }
.remixicon-close-fill:before { content: "\f055"; }
.remixicon-close-line:before { content: "\f056"; }
.remixicon-cloud-fill:before { content: "\f057"; }
.remixicon-cloud-line:before { content: "\f058"; }
.remixicon-code-fill:before { content: "\f059"; }
.remixicon-code-line:before { content: "\f05a"; }
.remixicon-code-view:before { content: "\f05b"; }
.remixicon-codepen-fill:before { content: "\f05c"; }
.remixicon-codepen-line:before { content: "\f05d"; }
.remixicon-compass-2-fill:before { content: "\f05e"; }
.remixicon-compass-2-line:before { content: "\f05f"; }
.remixicon-compass-3-fill:before { content: "\f060"; }
.remixicon-compass-3-line:before { content: "\f061"; }
.remixicon-compass-4-fill:before { content: "\f062"; }
.remixicon-compass-4-line:before { content: "\f063"; }
.remixicon-compass-discover-fill:before { content: "\f064"; }
.remixicon-compass-discover-line:before { content: "\f065"; }
.remixicon-compass-fill:before { content: "\f066"; }
.remixicon-compass-line:before { content: "\f067"; }
.remixicon-compasses-2-fill:before { content: "\f068"; }
.remixicon-compasses-2-line:before { content: "\f069"; }
.remixicon-compasses-fill:before { content: "\f06a"; }
.remixicon-compasses-line:before { content: "\f06b"; }
.remixicon-computer-fill:before { content: "\f06c"; }
.remixicon-computer-line:before { content: "\f06d"; }
.remixicon-contacts-book-2-fill:before { content: "\f06e"; }
.remixicon-contacts-book-2-line:before { content: "\f06f"; }
.remixicon-contacts-book-fill:before { content: "\f070"; }
.remixicon-contacts-book-line:before { content: "\f071"; }
.remixicon-contacts-fill:before { content: "\f072"; }
.remixicon-contacts-line:before { content: "\f073"; }
.remixicon-contrast-2-fill:before { content: "\f074"; }
.remixicon-contrast-2-line:before { content: "\f075"; }
.remixicon-contrast-drop-2-fill:before { content: "\f076"; }
.remixicon-contrast-drop-2-line:before { content: "\f077"; }
.remixicon-contrast-drop-fill:before { content: "\f078"; }
.remixicon-contrast-drop-line:before { content: "\f079"; }
.remixicon-contrast-fill:before { content: "\f07a"; }
.remixicon-contrast-line:before { content: "\f07b"; }
.remixicon-copper-coin-fill:before { content: "\f07c"; }
.remixicon-copper-coin-line:before { content: "\f07d"; }
.remixicon-copper-diamond-fill:before { content: "\f07e"; }
.remixicon-copper-diamond-line:before { content: "\f07f"; }
.remixicon-coupon-2-fill:before { content: "\f080"; }
.remixicon-coupon-2-line:before { content: "\f081"; }
.remixicon-coupon-3-fill:before { content: "\f082"; }
.remixicon-coupon-3-line:before { content: "\f083"; }
.remixicon-coupon-4-fill:before { content: "\f084"; }
.remixicon-coupon-4-line:before { content: "\f085"; }
.remixicon-coupon-5-fill:before { content: "\f086"; }
.remixicon-coupon-5-line:before { content: "\f087"; }
.remixicon-coupon-fill:before { content: "\f088"; }
.remixicon-coupon-line:before { content: "\f089"; }
.remixicon-cpu-fill:before { content: "\f08a"; }
.remixicon-cpu-line:before { content: "\f08b"; }
.remixicon-crop-2-fill:before { content: "\f08c"; }
.remixicon-crop-2-line:before { content: "\f08d"; }
.remixicon-crop-fill:before { content: "\f08e"; }
.remixicon-crop-line:before { content: "\f08f"; }
.remixicon-cup-fill:before { content: "\f090"; }
.remixicon-cup-line:before { content: "\f091"; }
.remixicon-customer-service-2-fill:before { content: "\f092"; }
.remixicon-customer-service-2-line:before { content: "\f093"; }
.remixicon-customer-service-fill:before { content: "\f094"; }
.remixicon-customer-service-line:before { content: "\f095"; }
.remixicon-dashboard-fill:before { content: "\f096"; }
.remixicon-dashboard-line:before { content: "\f097"; }
.remixicon-database-fill:before { content: "\f098"; }
.remixicon-database-line:before { content: "\f099"; }
.remixicon-delete-back-2-fill:before { content: "\f09a"; }
.remixicon-delete-back-2-line:before { content: "\f09b"; }
.remixicon-delete-back-fill:before { content: "\f09c"; }
.remixicon-delete-back-line:before { content: "\f09d"; }
.remixicon-delete-bin-2-fill:before { content: "\f09e"; }
.remixicon-delete-bin-2-line:before { content: "\f09f"; }
.remixicon-delete-bin-3-fill:before { content: "\f0a0"; }
.remixicon-delete-bin-3-line:before { content: "\f0a1"; }
.remixicon-delete-bin-4-fill:before { content: "\f0a2"; }
.remixicon-delete-bin-4-line:before { content: "\f0a3"; }
.remixicon-delete-bin-5-fill:before { content: "\f0a4"; }
.remixicon-delete-bin-5-line:before { content: "\f0a5"; }
.remixicon-delete-bin-6-fill:before { content: "\f0a6"; }
.remixicon-delete-bin-6-line:before { content: "\f0a7"; }
.remixicon-delete-bin-7-fill:before { content: "\f0a8"; }
.remixicon-delete-bin-7-line:before { content: "\f0a9"; }
.remixicon-delete-bin-fill:before { content: "\f0aa"; }
.remixicon-delete-bin-line:before { content: "\f0ab"; }
.remixicon-device-fill:before { content: "\f0ac"; }
.remixicon-device-line:before { content: "\f0ad"; }
.remixicon-dingding-fill:before { content: "\f0ae"; }
.remixicon-dingding-line:before { content: "\f0af"; }
.remixicon-direction-fill:before { content: "\f0b0"; }
.remixicon-direction-line:before { content: "\f0b1"; }
.remixicon-disc-fill:before { content: "\f0b2"; }
.remixicon-disc-line:before { content: "\f0b3"; }
.remixicon-discuss-fill:before { content: "\f0b4"; }
.remixicon-discuss-line:before { content: "\f0b5"; }
.remixicon-divide-fill:before { content: "\f0b6"; }
.remixicon-divide-line:before { content: "\f0b7"; }
.remixicon-download-2-fill:before { content: "\f0b8"; }
.remixicon-download-2-line:before { content: "\f0b9"; }
.remixicon-download-cloud-fill:before { content: "\f0ba"; }
.remixicon-download-cloud-line:before { content: "\f0bb"; }
.remixicon-download-fill:before { content: "\f0bc"; }
.remixicon-download-line:before { content: "\f0bd"; }
.remixicon-drag-move-2-fill:before { content: "\f0be"; }
.remixicon-drag-move-2-line:before { content: "\f0bf"; }
.remixicon-drag-move-fill:before { content: "\f0c0"; }
.remixicon-drag-move-line:before { content: "\f0c1"; }
.remixicon-dribbble-fill:before { content: "\f0c2"; }
.remixicon-dribbble-line:before { content: "\f0c3"; }
.remixicon-drive-fill:before { content: "\f0c4"; }
.remixicon-drive-line:before { content: "\f0c5"; }
.remixicon-drop-fill:before { content: "\f0c6"; }
.remixicon-drop-line:before { content: "\f0c7"; }
.remixicon-dropbox-fill:before { content: "\f0c8"; }
.remixicon-dropbox-line:before { content: "\f0c9"; }
.remixicon-dvd-fill:before { content: "\f0ca"; }
.remixicon-dvd-line:before { content: "\f0cb"; }
.remixicon-e-bike-2-fill:before { content: "\f0cc"; }
.remixicon-e-bike-2-line:before { content: "\f0cd"; }
.remixicon-e-bike-fill:before { content: "\f0ce"; }
.remixicon-e-bike-line:before { content: "\f0cf"; }
.remixicon-earth-fill:before { content: "\f0d0"; }
.remixicon-earth-line:before { content: "\f0d1"; }
.remixicon-edge-fill:before { content: "\f0d2"; }
.remixicon-edge-line:before { content: "\f0d3"; }
.remixicon-edit-2-fill:before { content: "\f0d4"; }
.remixicon-edit-2-line:before { content: "\f0d5"; }
.remixicon-edit-box-fill:before { content: "\f0d6"; }
.remixicon-edit-box-line:before { content: "\f0d7"; }
.remixicon-edit-circle-fill:before { content: "\f0d8"; }
.remixicon-edit-circle-line:before { content: "\f0d9"; }
.remixicon-edit-fill:before { content: "\f0da"; }
.remixicon-edit-line:before { content: "\f0db"; }
.remixicon-eject-fill:before { content: "\f0dc"; }
.remixicon-eject-line:before { content: "\f0dd"; }
.remixicon-emotion-2-fill:before { content: "\f0de"; }
.remixicon-emotion-2-line:before { content: "\f0df"; }
.remixicon-emotion-fill:before { content: "\f0e0"; }
.remixicon-emotion-happy-fill:before { content: "\f0e1"; }
.remixicon-emotion-happy-line:before { content: "\f0e2"; }
.remixicon-emotion-line:before { content: "\f0e3"; }
.remixicon-emotion-normal-fill:before { content: "\f0e4"; }
.remixicon-emotion-normal-line:before { content: "\f0e5"; }
.remixicon-emotion-unhappy-fill:before { content: "\f0e6"; }
.remixicon-emotion-unhappy-line:before { content: "\f0e7"; }
.remixicon-equalizer-fill:before { content: "\f0e8"; }
.remixicon-equalizer-line:before { content: "\f0e9"; }
.remixicon-eraser-fill:before { content: "\f0ea"; }
.remixicon-eraser-line:before { content: "\f0eb"; }
.remixicon-error-warning-fill:before { content: "\f0ec"; }
.remixicon-error-warning-line:before { content: "\f0ed"; }
.remixicon-evernote-fill:before { content: "\f0ee"; }
.remixicon-evernote-line:before { content: "\f0ef"; }
.remixicon-exchange-fill:before { content: "\f0f0"; }
.remixicon-exchange-line:before { content: "\f0f1"; }
.remixicon-eye-close-fill:before { content: "\f0f2"; }
.remixicon-eye-close-line:before { content: "\f0f3"; }
.remixicon-eye-fill:before { content: "\f0f4"; }
.remixicon-eye-line:before { content: "\f0f5"; }
.remixicon-eye-off-fill:before { content: "\f0f6"; }
.remixicon-eye-off-line:before { content: "\f0f7"; }
.remixicon-facebook-box-fill:before { content: "\f0f8"; }
.remixicon-facebook-box-line:before { content: "\f0f9"; }
.remixicon-facebook-fill:before { content: "\f0fa"; }
.remixicon-facebook-line:before { content: "\f0fb"; }
.remixicon-feedback-fill:before { content: "\f0fc"; }
.remixicon-feedback-line:before { content: "\f0fd"; }
.remixicon-file-2-fill:before { content: "\f0fe"; }
.remixicon-file-2-line:before { content: "\f0ff"; }
.remixicon-file-3-fill:before { content: "\f100"; }
.remixicon-file-3-line:before { content: "\f101"; }
.remixicon-file-4-fill:before { content: "\f102"; }
.remixicon-file-4-line:before { content: "\f103"; }
.remixicon-file-add-fill:before { content: "\f104"; }
.remixicon-file-add-line:before { content: "\f105"; }
.remixicon-file-chart-2-fill:before { content: "\f106"; }
.remixicon-file-chart-2-line:before { content: "\f107"; }
.remixicon-file-chart-fill:before { content: "\f108"; }
.remixicon-file-chart-line:before { content: "\f109"; }
.remixicon-file-code-fill:before { content: "\f10a"; }
.remixicon-file-code-line:before { content: "\f10b"; }
.remixicon-file-copy-2-fill:before { content: "\f10c"; }
.remixicon-file-copy-2-line:before { content: "\f10d"; }
.remixicon-file-copy-fill:before { content: "\f10e"; }
.remixicon-file-copy-line:before { content: "\f10f"; }
.remixicon-file-damage-fill:before { content: "\f110"; }
.remixicon-file-damage-line:before { content: "\f111"; }
.remixicon-file-download-fill:before { content: "\f112"; }
.remixicon-file-download-line:before { content: "\f113"; }
.remixicon-file-edit-fill:before { content: "\f114"; }
.remixicon-file-edit-line:before { content: "\f115"; }
.remixicon-file-excel-2-fill:before { content: "\f116"; }
.remixicon-file-excel-2-line:before { content: "\f117"; }
.remixicon-file-excel-fill:before { content: "\f118"; }
.remixicon-file-excel-line:before { content: "\f119"; }
.remixicon-file-fill:before { content: "\f11a"; }
.remixicon-file-forbid-fill:before { content: "\f11b"; }
.remixicon-file-forbid-line:before { content: "\f11c"; }
.remixicon-file-info-fill:before { content: "\f11d"; }
.remixicon-file-info-line:before { content: "\f11e"; }
.remixicon-file-line:before { content: "\f11f"; }
.remixicon-file-list-2-fill:before { content: "\f120"; }
.remixicon-file-list-2-line:before { content: "\f121"; }
.remixicon-file-list-3-fill:before { content: "\f122"; }
.remixicon-file-list-3-line:before { content: "\f123"; }
.remixicon-file-list-fill:before { content: "\f124"; }
.remixicon-file-list-line:before { content: "\f125"; }
.remixicon-file-lock-fill:before { content: "\f126"; }
.remixicon-file-lock-line:before { content: "\f127"; }
.remixicon-file-mark-fill:before { content: "\f128"; }
.remixicon-file-mark-line:before { content: "\f129"; }
.remixicon-file-music-fill:before { content: "\f12a"; }
.remixicon-file-music-line:before { content: "\f12b"; }
.remixicon-file-paper-fill:before { content: "\f12c"; }
.remixicon-file-paper-line:before { content: "\f12d"; }
.remixicon-file-pdf-fill:before { content: "\f12e"; }
.remixicon-file-pdf-line:before { content: "\f12f"; }
.remixicon-file-ppt-2-fill:before { content: "\f130"; }
.remixicon-file-ppt-2-line:before { content: "\f131"; }
.remixicon-file-ppt-fill:before { content: "\f132"; }
.remixicon-file-ppt-2-line:before { content: "\f133"; }
.remixicon-file-ppt-line:before { content: "\f134"; }
.remixicon-file-reduce-fill:before { content: "\f135"; }
.remixicon-file-reduce-line:before { content: "\f136"; }
.remixicon-file-search-fill:before { content: "\f137"; }
.remixicon-file-search-line:before { content: "\f138"; }
.remixicon-file-settings-fill:before { content: "\f139"; }
.remixicon-file-settings-line:before { content: "\f13a"; }
.remixicon-file-shred-fill:before { content: "\f13b"; }
.remixicon-file-shred-line:before { content: "\f13c"; }
.remixicon-file-text-fill:before { content: "\f13d"; }
.remixicon-file-text-line:before { content: "\f13e"; }
.remixicon-file-transfer-fill:before { content: "\f13f"; }
.remixicon-file-transfer-line:before { content: "\f140"; }
.remixicon-file-unknow-fill:before { content: "\f141"; }
.remixicon-file-unknow-line:before { content: "\f142"; }
.remixicon-file-upload-fill:before { content: "\f143"; }
.remixicon-file-upload-line:before { content: "\f144"; }
.remixicon-file-user-fill:before { content: "\f145"; }
.remixicon-file-user-line:before { content: "\f146"; }
.remixicon-file-warning-fill:before { content: "\f147"; }
.remixicon-file-warning-line:before { content: "\f148"; }
.remixicon-file-word-2-fill:before { content: "\f149"; }
.remixicon-file-word-2-line:before { content: "\f14a"; }
.remixicon-file-word-fill:before { content: "\f14b"; }
.remixicon-file-word-line:before { content: "\f14c"; }
.remixicon-file-zip-fill:before { content: "\f14d"; }
.remixicon-file-zip-line:before { content: "\f14e"; }
.remixicon-film-fill:before { content: "\f14f"; }
.remixicon-film-line:before { content: "\f150"; }
.remixicon-find-replace-fill:before { content: "\f151"; }
.remixicon-find-replace-line:before { content: "\f152"; }
.remixicon-firefox-fill:before { content: "\f153"; }
.remixicon-firefox-line:before { content: "\f154"; }
.remixicon-flag-2-fill:before { content: "\f155"; }
.remixicon-flag-2-line:before { content: "\f156"; }
.remixicon-flag-fill:before { content: "\f157"; }
.remixicon-flag-line:before { content: "\f158"; }
.remixicon-flight-land-fill:before { content: "\f159"; }
.remixicon-flight-land-line:before { content: "\f15a"; }
.remixicon-flight-takeoff-fill:before { content: "\f15b"; }
.remixicon-flight-takeoff-line:before { content: "\f15c"; }
.remixicon-focus-2-fill:before { content: "\f15d"; }
.remixicon-focus-2-line:before { content: "\f15e"; }
.remixicon-focus-fill:before { content: "\f15f"; }
.remixicon-focus-line:before { content: "\f160"; }
.remixicon-folder-2-fill:before { content: "\f161"; }
.remixicon-folder-2-line:before { content: "\f162"; }
.remixicon-folder-3-fill:before { content: "\f163"; }
.remixicon-folder-3-line:before { content: "\f164"; }
.remixicon-folder-4-fill:before { content: "\f165"; }
.remixicon-folder-4-line:before { content: "\f166"; }
.remixicon-folder-5-fill:before { content: "\f167"; }
.remixicon-folder-5-line:before { content: "\f168"; }
.remixicon-folder-add-fill:before { content: "\f169"; }
.remixicon-folder-add-line:before { content: "\f16a"; }
.remixicon-folder-chart-2-fill:before { content: "\f16b"; }
.remixicon-folder-chart-2-line:before { content: "\f16c"; }
.remixicon-folder-chart-fill:before { content: "\f16d"; }
.remixicon-folder-chart-line:before { content: "\f16e"; }
.remixicon-folder-download-fill:before { content: "\f16f"; }
.remixicon-folder-download-line:before { content: "\f170"; }
.remixicon-folder-fill:before { content: "\f171"; }
.remixicon-folder-forbid-fill:before { content: "\f172"; }
.remixicon-folder-forbid-line:before { content: "\f173"; }
.remixicon-folder-info-fill:before { content: "\f174"; }
.remixicon-folder-info-line:before { content: "\f175"; }
.remixicon-folder-line:before { content: "\f176"; }
.remixicon-folder-lock-fill:before { content: "\f177"; }
.remixicon-folder-lock-line:before { content: "\f178"; }
.remixicon-folder-music-fill:before { content: "\f179"; }
.remixicon-folder-music-line:before { content: "\f17a"; }
.remixicon-folder-reduce-fill:before { content: "\f17b"; }
.remixicon-folder-reduce-line:before { content: "\f17c"; }
.remixicon-folder-settings-fill:before { content: "\f17d"; }
.remixicon-folder-settings-line:before { content: "\f17e"; }
.remixicon-folder-transfer-fill:before { content: "\f17f"; }
.remixicon-folder-transfer-line:before { content: "\f180"; }
.remixicon-folder-unknow-fill:before { content: "\f181"; }
.remixicon-folder-unknow-line:before { content: "\f182"; }
.remixicon-folder-upload-fill:before { content: "\f183"; }
.remixicon-folder-upload-line:before { content: "\f184"; }
.remixicon-folder-user-fill:before { content: "\f185"; }
.remixicon-folder-user-line:before { content: "\f186"; }
.remixicon-folder-warning-fill:before { content: "\f187"; }
.remixicon-folder-warning-line:before { content: "\f188"; }
.remixicon-font-color:before { content: "\f189"; }
.remixicon-font-size-2:before { content: "\f18a"; }
.remixicon-font-size:before { content: "\f18b"; }
.remixicon-footprint-fill:before { content: "\f18c"; }
.remixicon-footprint-line:before { content: "\f18d"; }
.remixicon-forbid-2-fill:before { content: "\f18e"; }
.remixicon-forbid-2-line:before { content: "\f18f"; }
.remixicon-forbid-fill:before { content: "\f190"; }
.remixicon-forbid-line:before { content: "\f191"; }
.remixicon-fullscreen-exit-fill:before { content: "\f192"; }
.remixicon-fullscreen-exit-line:before { content: "\f193"; }
.remixicon-fullscreen-fill:before { content: "\f194"; }
.remixicon-fullscreen-line:before { content: "\f195"; }
.remixicon-function-fill:before { content: "\f196"; }
.remixicon-function-line:before { content: "\f197"; }
.remixicon-gallery-fill:before { content: "\f198"; }
.remixicon-gallery-line:before { content: "\f199"; }
.remixicon-gas-station-fill:before { content: "\f19a"; }
.remixicon-gas-station-line:before { content: "\f19b"; }
.remixicon-github-fill:before { content: "\f19c"; }
.remixicon-github-line:before { content: "\f19d"; }
.remixicon-gitlab-fill:before { content: "\f19e"; }
.remixicon-gitlab-line:before { content: "\f19f"; }
.remixicon-global-fill:before { content: "\f1a0"; }
.remixicon-global-line:before { content: "\f1a1"; }
.remixicon-globe-fill:before { content: "\f1a2"; }
.remixicon-globe-line:before { content: "\f1a3"; }
.remixicon-goblet-fill:before { content: "\f1a4"; }
.remixicon-goblet-line:before { content: "\f1a5"; }
.remixicon-google-fill:before { content: "\f1a6"; }
.remixicon-google-line:before { content: "\f1a7"; }
.remixicon-government-fill:before { content: "\f1a8"; }
.remixicon-government-line:before { content: "\f1a9"; }
.remixicon-gps-fill:before { content: "\f1aa"; }
.remixicon-gps-line:before { content: "\f1ab"; }
.remixicon-gradienter-fill:before { content: "\f1ac"; }
.remixicon-gradienter-line:before { content: "\f1ad"; }
.remixicon-grid-fill:before { content: "\f1ae"; }
.remixicon-grid-line:before { content: "\f1af"; }
.remixicon-group-fill:before { content: "\f1b0"; }
.remixicon-group-line:before { content: "\f1b1"; }
.remixicon-guide-fill:before { content: "\f1b2"; }
.remixicon-guide-line:before { content: "\f1b3"; }
.remixicon-hard-drive-2-fill:before { content: "\f1b4"; }
.remixicon-hard-drive-2-line:before { content: "\f1b5"; }
.remixicon-hard-drive-fill:before { content: "\f1b6"; }
.remixicon-hard-drive-line:before { content: "\f1b7"; }
.remixicon-headphone-fill:before { content: "\f1b8"; }
.remixicon-headphone-line:before { content: "\f1b9"; }
.remixicon-heart-2-fill:before { content: "\f1ba"; }
.remixicon-heart-2-line:before { content: "\f1bb"; }
.remixicon-heart-fill:before { content: "\f1bc"; }
.remixicon-heart-line:before { content: "\f1bd"; }
.remixicon-home-2-fill:before { content: "\f1be"; }
.remixicon-home-2-line:before { content: "\f1bf"; }
.remixicon-home-3-fill:before { content: "\f1c0"; }
.remixicon-home-3-line:before { content: "\f1c1"; }
.remixicon-home-4-fill:before { content: "\f1c2"; }
.remixicon-home-4-line:before { content: "\f1c3"; }
.remixicon-home-5-fill:before { content: "\f1c4"; }
.remixicon-home-5-line:before { content: "\f1c5"; }
.remixicon-home-6-fill:before { content: "\f1c6"; }
.remixicon-home-6-line:before { content: "\f1c7"; }
.remixicon-home-7-fill:before { content: "\f1c8"; }
.remixicon-home-7-line:before { content: "\f1c9"; }
.remixicon-home-8-fill:before { content: "\f1ca"; }
.remixicon-home-8-line:before { content: "\f1cb"; }
.remixicon-home-fill:before { content: "\f1cc"; }
.remixicon-home-gear-fill:before { content: "\f1cd"; }
.remixicon-home-gear-line:before { content: "\f1ce"; }
.remixicon-home-line:before { content: "\f1cf"; }
.remixicon-honour-fill:before { content: "\f1d0"; }
.remixicon-honour-line:before { content: "\f1d1"; }
.remixicon-hotel-bed-fill:before { content: "\f1d2"; }
.remixicon-hotel-bed-line:before { content: "\f1d3"; }
.remixicon-ie-fill:before { content: "\f1d4"; }
.remixicon-ie-line:before { content: "\f1d5"; }
.remixicon-image-2-fill:before { content: "\f1d6"; }
.remixicon-image-2-line:before { content: "\f1d7"; }
.remixicon-image-fill:before { content: "\f1d8"; }
.remixicon-image-line:before { content: "\f1d9"; }
.remixicon-inbox-archive-fill:before { content: "\f1da"; }
.remixicon-inbox-archive-line:before { content: "\f1db"; }
.remixicon-inbox-fill:before { content: "\f1dc"; }
.remixicon-inbox-line:before { content: "\f1dd"; }
.remixicon-increase-decrease-fill:before { content: "\f1de"; }
.remixicon-increase-decrease-line:before { content: "\f1df"; }
.remixicon-indent-decrease:before { content: "\f1e0"; }
.remixicon-indent-increase:before { content: "\f1e1"; }
.remixicon-indeterminate-circle-fill:before { content: "\f1e2"; }
.remixicon-indeterminate-circle-line:before { content: "\f1e3"; }
.remixicon-information-fill:before { content: "\f1e4"; }
.remixicon-information-line:before { content: "\f1e5"; }
.remixicon-input-method-fill:before { content: "\f1e6"; }
.remixicon-input-method-line:before { content: "\f1e7"; }
.remixicon-instagram-fill:before { content: "\f1e8"; }
.remixicon-instagram-line:before { content: "\f1e9"; }
.remixicon-invision-fill:before { content: "\f1ea"; }
.remixicon-invision-line:before { content: "\f1eb"; }
.remixicon-italic:before { content: "\f1ec"; }
.remixicon-keyboard-box-fill:before { content: "\f1ed"; }
.remixicon-keyboard-box-line:before { content: "\f1ee"; }
.remixicon-keyboard-fill:before { content: "\f1ef"; }
.remixicon-keyboard-line:before { content: "\f1f0"; }
.remixicon-keynote-fill:before { content: "\f1f1"; }
.remixicon-keynote-line:before { content: "\f1f2"; }
.remixicon-landscape-fill:before { content: "\f1f3"; }
.remixicon-landscape-line:before { content: "\f1f4"; }
.remixicon-layout-fill:before { content: "\f1f5"; }
.remixicon-layout-line:before { content: "\f1f6"; }
.remixicon-line-fill:before { content: "\f1f7"; }
.remixicon-line-height:before { content: "\f1f8"; }
.remixicon-line-line:before { content: "\f1f9"; }
.remixicon-link-unlink:before { content: "\f1fa"; }
.remixicon-link:before { content: "\f1fb"; }
.remixicon-linkedin-box-fill:before { content: "\f1fc"; }
.remixicon-linkedin-box-line:before { content: "\f1fd"; }
.remixicon-linkedin-fill:before { content: "\f1fe"; }
.remixicon-linkedin-line:before { content: "\f1ff"; }
.remixicon-links-fill:before { content: "\f200"; }
.remixicon-links-line:before { content: "\f201"; }
.remixicon-list-check:before { content: "\f202"; }
.remixicon-list-ordered:before { content: "\f203"; }
.remixicon-list-settings-fill:before { content: "\f204"; }
.remixicon-list-settings-line:before { content: "\f205"; }
.remixicon-list-unordered:before { content: "\f206"; }
.remixicon-lock-2-fill:before { content: "\f207"; }
.remixicon-lock-2-line:before { content: "\f208"; }
.remixicon-lock-fill:before { content: "\f209"; }
.remixicon-lock-line:before { content: "\f20a"; }
.remixicon-lock-password-fill:before { content: "\f20b"; }
.remixicon-lock-password-line:before { content: "\f20c"; }
.remixicon-lock-unlock-fill:before { content: "\f20d"; }
.remixicon-lock-unlock-line:before { content: "\f20e"; }
.remixicon-login-box-fill:before { content: "\f20f"; }
.remixicon-login-box-line:before { content: "\f210"; }
.remixicon-login-circle-fill:before { content: "\f211"; }
.remixicon-login-circle-line:before { content: "\f212"; }
.remixicon-logout-box-fill:before { content: "\f213"; }
.remixicon-logout-box-line:before { content: "\f214"; }
.remixicon-logout-circle-fill:before { content: "\f215"; }
.remixicon-logout-circle-line:before { content: "\f216"; }
.remixicon-mac-fill:before { content: "\f217"; }
.remixicon-mac-line:before { content: "\f218"; }
.remixicon-macbook-fill:before { content: "\f219"; }
.remixicon-macbook-line:before { content: "\f21a"; }
.remixicon-mail-fill:before { content: "\f21b"; }
.remixicon-mail-line:before { content: "\f21c"; }
.remixicon-mail-open-fill:before { content: "\f21d"; }
.remixicon-mail-open-line:before { content: "\f21e"; }
.remixicon-mail-send-fill:before { content: "\f21f"; }
.remixicon-mail-send-line:before { content: "\f220"; }
.remixicon-mail-unread-fill:before { content: "\f221"; }
.remixicon-mail-unread-line:before { content: "\f222"; }
.remixicon-map-2-fill:before { content: "\f223"; }
.remixicon-map-2-line:before { content: "\f224"; }
.remixicon-map-fill:before { content: "\f225"; }
.remixicon-map-line:before { content: "\f226"; }
.remixicon-map-pin-2-fill:before { content: "\f227"; }
.remixicon-map-pin-2-line:before { content: "\f228"; }
.remixicon-map-pin-3-fill:before { content: "\f229"; }
.remixicon-map-pin-3-line:before { content: "\f22a"; }
.remixicon-map-pin-4-fill:before { content: "\f22b"; }
.remixicon-map-pin-4-line:before { content: "\f22c"; }
.remixicon-map-pin-5-fill:before { content: "\f22d"; }
.remixicon-map-pin-5-line:before { content: "\f22e"; }
.remixicon-map-pin-add-fill:before { content: "\f22f"; }
.remixicon-map-pin-add-line:before { content: "\f230"; }
.remixicon-map-pin-fill:before { content: "\f231"; }
.remixicon-map-pin-line:before { content: "\f232"; }
.remixicon-map-pin-range-fill:before { content: "\f233"; }
.remixicon-map-pin-range-line:before { content: "\f234"; }
.remixicon-map-pin-time-fill:before { content: "\f235"; }
.remixicon-map-pin-time-line:before { content: "\f236"; }
.remixicon-map-pin-user-fill:before { content: "\f237"; }
.remixicon-map-pin-user-line:before { content: "\f238"; }
.remixicon-mark-pen-fill:before { content: "\f239"; }
.remixicon-mark-pen-line:before { content: "\f23a"; }
.remixicon-markdown-fill:before { content: "\f23b"; }
.remixicon-markdown-line:before { content: "\f23c"; }
.remixicon-medium-fill:before { content: "\f23d"; }
.remixicon-medium-line:before { content: "\f23e"; }
.remixicon-men-fill:before { content: "\f23f"; }
.remixicon-men-line:before { content: "\f240"; }
.remixicon-menu-2-fill:before { content: "\f241"; }
.remixicon-menu-2-line:before { content: "\f242"; }
.remixicon-menu-3-fill:before { content: "\f243"; }
.remixicon-menu-3-line:before { content: "\f244"; }
.remixicon-menu-fill:before { content: "\f245"; }
.remixicon-menu-line:before { content: "\f246"; }
.remixicon-message-2-fill:before { content: "\f247"; }
.remixicon-message-2-line:before { content: "\f248"; }
.remixicon-message-3-fill:before { content: "\f249"; }
.remixicon-message-3-line:before { content: "\f24a"; }
.remixicon-message-fill:before { content: "\f24b"; }
.remixicon-message-line:before { content: "\f24c"; }
.remixicon-messenger-fill:before { content: "\f24d"; }
.remixicon-messenger-line:before { content: "\f24e"; }
.remixicon-mic-2-fill:before { content: "\f24f"; }
.remixicon-mic-2-line:before { content: "\f250"; }
.remixicon-mic-fill:before { content: "\f251"; }
.remixicon-mic-line:before { content: "\f252"; }
.remixicon-mic-off-fill:before { content: "\f253"; }
.remixicon-mic-off-line:before { content: "\f254"; }
.remixicon-money-cny-box-fill:before { content: "\f255"; }
.remixicon-money-cny-box-line:before { content: "\f256"; }
.remixicon-money-cny-circle-fill:before { content: "\f257"; }
.remixicon-money-cny-circle-line:before { content: "\f258"; }
.remixicon-money-dollar-box-fill:before { content: "\f259"; }
.remixicon-money-dollar-box-line:before { content: "\f25a"; }
.remixicon-money-dollar-circle-fill:before { content: "\f25b"; }
.remixicon-money-dollar-circle-line:before { content: "\f25c"; }
.remixicon-money-euro-box-fill:before { content: "\f25d"; }
.remixicon-money-euro-box-line:before { content: "\f25e"; }
.remixicon-money-euro-circle-fill:before { content: "\f25f"; }
.remixicon-money-euro-circle-line:before { content: "\f260"; }
.remixicon-money-pound-box-fill:before { content: "\f261"; }
.remixicon-money-pound-box-line:before { content: "\f262"; }
.remixicon-money-pound-circle-fill:before { content: "\f263"; }
.remixicon-money-pound-circle-line:before { content: "\f264"; }
.remixicon-moon-fill:before { content: "\f265"; }
.remixicon-moon-line:before { content: "\f266"; }
.remixicon-more-2-fill:before { content: "\f267"; }
.remixicon-more-2-line:before { content: "\f268"; }
.remixicon-more-fill:before { content: "\f269"; }
.remixicon-more-line:before { content: "\f26a"; }
.remixicon-motorbike-fill:before { content: "\f26b"; }
.remixicon-motorbike-line:before { content: "\f26c"; }
.remixicon-mouse-fill:before { content: "\f26d"; }
.remixicon-mouse-line:before { content: "\f26e"; }
.remixicon-movie-2-fill:before { content: "\f26f"; }
.remixicon-movie-2-line:before { content: "\f270"; }
.remixicon-movie-fill:before { content: "\f271"; }
.remixicon-movie-line:before { content: "\f272"; }
.remixicon-music-2-fill:before { content: "\f273"; }
.remixicon-music-2-line:before { content: "\f274"; }
.remixicon-music-fill:before { content: "\f275"; }
.remixicon-music-line:before { content: "\f276"; }
.remixicon-mv-fill:before { content: "\f277"; }
.remixicon-mv-line:before { content: "\f278"; }
.remixicon-navigation-fill:before { content: "\f279"; }
.remixicon-navigation-line:before { content: "\f27a"; }
.remixicon-newspaper-fill:before { content: "\f27b"; }
.remixicon-newspaper-line:before { content: "\f27c"; }
.remixicon-notification-2-fill:before { content: "\f27d"; }
.remixicon-notification-2-line:before { content: "\f27e"; }
.remixicon-notification-3-fill:before { content: "\f27f"; }
.remixicon-notification-3-line:before { content: "\f280"; }
.remixicon-notification-4-fill:before { content: "\f281"; }
.remixicon-notification-4-line:before { content: "\f282"; }
.remixicon-notification-badge-fill:before { content: "\f283"; }
.remixicon-notification-badge-line:before { content: "\f284"; }
.remixicon-notification-fill:before { content: "\f285"; }
.remixicon-notification-line:before { content: "\f286"; }
.remixicon-notification-off-fill:before { content: "\f287"; }
.remixicon-notification-off-line:before { content: "\f288"; }
.remixicon-numbers-fill:before { content: "\f289"; }
.remixicon-numbers-line:before { content: "\f28a"; }
.remixicon-oil-fill:before { content: "\f28b"; }
.remixicon-oil-line:before { content: "\f28c"; }
.remixicon-open-arm-fill:before { content: "\f28d"; }
.remixicon-open-arm-line:before { content: "\f28e"; }
.remixicon-opera-fill:before { content: "\f28f"; }
.remixicon-opera-line:before { content: "\f290"; }
.remixicon-order-play-fill:before { content: "\f291"; }
.remixicon-order-play-line:before { content: "\f292"; }
.remixicon-pages-fill:before { content: "\f293"; }
.remixicon-pages-line:before { content: "\f294"; }
.remixicon-paint-brush-fill:before { content: "\f295"; }
.remixicon-paint-brush-line:before { content: "\f296"; }
.remixicon-paint-fill:before { content: "\f297"; }
.remixicon-paint-line:before { content: "\f298"; }
.remixicon-palette-fill:before { content: "\f299"; }
.remixicon-palette-line:before { content: "\f29a"; }
.remixicon-pantone-fill:before { content: "\f29b"; }
.remixicon-pantone-line:before { content: "\f29c"; }
.remixicon-parking-box-fill:before { content: "\f29d"; }
.remixicon-parking-box-line:before { content: "\f29e"; }
.remixicon-parking-fill:before { content: "\f29f"; }
.remixicon-parking-line:before { content: "\f2a0"; }
.remixicon-pause-circle-fill:before { content: "\f2a1"; }
.remixicon-pause-circle-line:before { content: "\f2a2"; }
.remixicon-pause-fill:before { content: "\f2a3"; }
.remixicon-pause-line:before { content: "\f2a4"; }
.remixicon-pause-mini-fill:before { content: "\f2a5"; }
.remixicon-pause-mini-line:before { content: "\f2a6"; }
.remixicon-paypal-fill:before { content: "\f2a7"; }
.remixicon-paypal-line:before { content: "\f2a8"; }
.remixicon-pen-nib-fill:before { content: "\f2a9"; }
.remixicon-pen-nib-line:before { content: "\f2aa"; }
.remixicon-pencil-fill:before { content: "\f2ab"; }
.remixicon-pencil-line:before { content: "\f2ac"; }
.remixicon-pencil-ruler-2-fill:before { content: "\f2ad"; }
.remixicon-pencil-ruler-2-line:before { content: "\f2ae"; }
.remixicon-pencil-ruler-fill:before { content: "\f2af"; }
.remixicon-pencil-ruler-line:before { content: "\f2b0"; }
.remixicon-percent-fill:before { content: "\f2b1"; }
.remixicon-percent-line:before { content: "\f2b2"; }
.remixicon-phone-camera-fill:before { content: "\f2b3"; }
.remixicon-phone-camera-line:before { content: "\f2b4"; }
.remixicon-phone-fill:before { content: "\f2b5"; }
.remixicon-phone-line:before { content: "\f2b6"; }
.remixicon-pie-chart-2-fill:before { content: "\f2b7"; }
.remixicon-pie-chart-2-line:before { content: "\f2b8"; }
.remixicon-pie-chart-box-fill:before { content: "\f2b9"; }
.remixicon-pie-chart-box-line:before { content: "\f2ba"; }
.remixicon-pie-chart-fill:before { content: "\f2bb"; }
.remixicon-pie-chart-line:before { content: "\f2bc"; }
.remixicon-pin-distance-fill:before { content: "\f2bd"; }
.remixicon-pin-distance-line:before { content: "\f2be"; }
.remixicon-pinterest-fill:before { content: "\f2bf"; }
.remixicon-pinterest-line:before { content: "\f2c0"; }
.remixicon-plane-fill:before { content: "\f2c1"; }
.remixicon-plane-line:before { content: "\f2c2"; }
.remixicon-play-circle-fill:before { content: "\f2c3"; }
.remixicon-play-circle-line:before { content: "\f2c4"; }
.remixicon-play-fill:before { content: "\f2c5"; }
.remixicon-play-line:before { content: "\f2c6"; }
.remixicon-play-list-add-fill:before { content: "\f2c7"; }
.remixicon-play-list-add-line:before { content: "\f2c8"; }
.remixicon-play-list-fill:before { content: "\f2c9"; }
.remixicon-play-list-line:before { content: "\f2ca"; }
.remixicon-play-mini-fill:before { content: "\f2cb"; }
.remixicon-play-mini-line:before { content: "\f2cc"; }
.remixicon-polaroid-2-fill:before { content: "\f2cd"; }
.remixicon-polaroid-2-line:before { content: "\f2ce"; }
.remixicon-polaroid-fill:before { content: "\f2cf"; }
.remixicon-polaroid-line:before { content: "\f2d0"; }
.remixicon-police-car-fill:before { content: "\f2d1"; }
.remixicon-police-car-line:before { content: "\f2d2"; }
.remixicon-price-tag-2-fill:before { content: "\f2d3"; }
.remixicon-price-tag-2-line:before { content: "\f2d4"; }
.remixicon-price-tag-3-fill:before { content: "\f2d5"; }
.remixicon-price-tag-3-line:before { content: "\f2d6"; }
.remixicon-price-tag-fill:before { content: "\f2d7"; }
.remixicon-price-tag-line:before { content: "\f2d8"; }
.remixicon-printer-fill:before { content: "\f2d9"; }
.remixicon-printer-line:before { content: "\f2da"; }
.remixicon-product-hunt-fill:before { content: "\f2db"; }
.remixicon-product-hunt-line:before { content: "\f2dc"; }
.remixicon-profile-fill:before { content: "\f2dd"; }
.remixicon-profile-line:before { content: "\f2de"; }
.remixicon-projector-2-fill:before { content: "\f2df"; }
.remixicon-projector-2-line:before { content: "\f2e0"; }
.remixicon-projector-fill:before { content: "\f2e1"; }
.remixicon-projector-line:before { content: "\f2e2"; }
.remixicon-qq-fill:before { content: "\f2e3"; }
.remixicon-qq-line:before { content: "\f2e4"; }
.remixicon-qr-code-fill:before { content: "\f2e5"; }
.remixicon-qr-code-line:before { content: "\f2e6"; }
.remixicon-qr-scan-2-fill:before { content: "\f2e7"; }
.remixicon-qr-scan-2-line:before { content: "\f2e8"; }
.remixicon-qr-scan-fill:before { content: "\f2e9"; }
.remixicon-qr-scan-line:before { content: "\f2ea"; }
.remixicon-question-answer-fill:before { content: "\f2eb"; }
.remixicon-question-answer-line:before { content: "\f2ec"; }
.remixicon-question-fill:before { content: "\f2ed"; }
.remixicon-question-line:before { content: "\f2ee"; }
.remixicon-questionnaire-fill:before { content: "\f2ef"; }
.remixicon-questionnaire-line:before { content: "\f2f0"; }
.remixicon-quill-pen-fill:before { content: "\f2f1"; }
.remixicon-quill-pen-line:before { content: "\f2f2"; }
.remixicon-radio-2-fill:before { content: "\f2f3"; }
.remixicon-radio-2-line:before { content: "\f2f4"; }
.remixicon-radio-button-fill:before { content: "\f2f5"; }
.remixicon-radio-button-line:before { content: "\f2f6"; }
.remixicon-radio-fill:before { content: "\f2f7"; }
.remixicon-radio-line:before { content: "\f2f8"; }
.remixicon-record-circle-fill:before { content: "\f2f9"; }
.remixicon-record-circle-line:before { content: "\f2fa"; }
.remixicon-record-mail-fill:before { content: "\f2fb"; }
.remixicon-record-mail-line:before { content: "\f2fc"; }
.remixicon-refresh-fill:before { content: "\f2fd"; }
.remixicon-refresh-line:before { content: "\f2fe"; }
.remixicon-repeat-2-fill:before { content: "\f2ff"; }
.remixicon-repeat-2-line:before { content: "\f300"; }
.remixicon-repeat-fill:before { content: "\f301"; }
.remixicon-repeat-line:before { content: "\f302"; }
.remixicon-repeat-one-fill:before { content: "\f303"; }
.remixicon-repeat-one-line:before { content: "\f304"; }
.remixicon-reply-fill:before { content: "\f305"; }
.remixicon-reply-line:before { content: "\f306"; }
.remixicon-restart-fill:before { content: "\f307"; }
.remixicon-restart-line:before { content: "\f308"; }
.remixicon-restaurant-2-fill:before { content: "\f309"; }
.remixicon-restaurant-2-line:before { content: "\f30a"; }
.remixicon-restaurant-fill:before { content: "\f30b"; }
.remixicon-restaurant-line:before { content: "\f30c"; }
.remixicon-rewind-fill:before { content: "\f30d"; }
.remixicon-rewind-line:before { content: "\f30e"; }
.remixicon-rewind-mini-fill:before { content: "\f30f"; }
.remixicon-rewind-mini-line:before { content: "\f310"; }
.remixicon-rhythm-fill:before { content: "\f311"; }
.remixicon-rhythm-line:before { content: "\f312"; }
.remixicon-riding-fill:before { content: "\f313"; }
.remixicon-riding-line:before { content: "\f314"; }
.remixicon-road-map-fill:before { content: "\f315"; }
.remixicon-road-map-line:before { content: "\f316"; }
.remixicon-roadster-fill:before { content: "\f317"; }
.remixicon-roadster-line:before { content: "\f318"; }
.remixicon-rocket-2-fill:before { content: "\f319"; }
.remixicon-rocket-2-line:before { content: "\f31a"; }
.remixicon-rocket-fill:before { content: "\f31b"; }
.remixicon-rocket-line:before { content: "\f31c"; }
.remixicon-route-fill:before { content: "\f31d"; }
.remixicon-route-line:before { content: "\f31e"; }
.remixicon-router-fill:before { content: "\f31f"; }
.remixicon-router-line:before { content: "\f320"; }
.remixicon-rss-fill:before { content: "\f321"; }
.remixicon-rss-line:before { content: "\f322"; }
.remixicon-ruler-2-fill:before { content: "\f323"; }
.remixicon-ruler-2-line:before { content: "\f324"; }
.remixicon-ruler-fill:before { content: "\f325"; }
.remixicon-ruler-line:before { content: "\f326"; }
.remixicon-run-fill:before { content: "\f327"; }
.remixicon-run-line:before { content: "\f328"; }
.remixicon-safari-fill:before { content: "\f329"; }
.remixicon-safari-line:before { content: "\f32a"; }
.remixicon-safe-2-fill:before { content: "\f32b"; }
.remixicon-safe-2-line:before { content: "\f32c"; }
.remixicon-safe-fill:before { content: "\f32d"; }
.remixicon-safe-line:before { content: "\f32e"; }
.remixicon-sailboat-fill:before { content: "\f32f"; }
.remixicon-sailboat-line:before { content: "\f330"; }
.remixicon-save-2-fill:before { content: "\f331"; }
.remixicon-save-2-line:before { content: "\f332"; }
.remixicon-save-3-fill:before { content: "\f333"; }
.remixicon-save-3-line:before { content: "\f334"; }
.remixicon-save-fill:before { content: "\f335"; }
.remixicon-save-line:before { content: "\f336"; }
.remixicon-scan-2-fill:before { content: "\f337"; }
.remixicon-scan-2-line:before { content: "\f338"; }
.remixicon-scan-fill:before { content: "\f339"; }
.remixicon-scan-line:before { content: "\f33a"; }
.remixicon-scissors-2-fill:before { content: "\f33b"; }
.remixicon-scissors-2-line:before { content: "\f33c"; }
.remixicon-scissors-cut-fill:before { content: "\f33d"; }
.remixicon-scissors-cut-line:before { content: "\f33e"; }
.remixicon-scissors-fill:before { content: "\f33f"; }
.remixicon-scissors-line:before { content: "\f340"; }
.remixicon-sd-card-fill:before { content: "\f341"; }
.remixicon-sd-card-line:before { content: "\f342"; }
.remixicon-sd-card-mini-fill:before { content: "\f343"; }
.remixicon-sd-card-mini-line:before { content: "\f344"; }
.remixicon-search-2-fill:before { content: "\f345"; }
.remixicon-search-2-line:before { content: "\f346"; }
.remixicon-search-eye-fill:before { content: "\f347"; }
.remixicon-search-eye-line:before { content: "\f348"; }
.remixicon-search-fill:before { content: "\f349"; }
.remixicon-search-line:before { content: "\f34a"; }
.remixicon-send-plane-2-fill:before { content: "\f34b"; }
.remixicon-send-plane-2-line:before { content: "\f34c"; }
.remixicon-send-plane-fill:before { content: "\f34d"; }
.remixicon-send-plane-line:before { content: "\f34e"; }
.remixicon-sensor-fill:before { content: "\f34f"; }
.remixicon-sensor-line:before { content: "\f350"; }
.remixicon-separator:before { content: "\f351"; }
.remixicon-server-fill:before { content: "\f352"; }
.remixicon-server-line:before { content: "\f353"; }
.remixicon-settings-2-fill:before { content: "\f354"; }
.remixicon-settings-2-line:before { content: "\f355"; }
.remixicon-settings-3-fill:before { content: "\f356"; }
.remixicon-settings-3-line:before { content: "\f357"; }
.remixicon-settings-4-fill:before { content: "\f358"; }
.remixicon-settings-4-line:before { content: "\f359"; }
.remixicon-settings-5-fill:before { content: "\f35a"; }
.remixicon-settings-5-line:before { content: "\f35b"; }
.remixicon-settings-6-fill:before { content: "\f35c"; }
.remixicon-settings-6-line:before { content: "\f35d"; }
.remixicon-settings-fill:before { content: "\f35e"; }
.remixicon-settings-line:before { content: "\f35f"; }
.remixicon-shape-2-fill:before { content: "\f360"; }
.remixicon-shape-2-line:before { content: "\f361"; }
.remixicon-shape-fill:before { content: "\f362"; }
.remixicon-shape-line:before { content: "\f363"; }
.remixicon-share-box-fill:before { content: "\f364"; }
.remixicon-share-box-line:before { content: "\f365"; }
.remixicon-share-circle-fill:before { content: "\f366"; }
.remixicon-share-circle-line:before { content: "\f367"; }
.remixicon-share-fill:before { content: "\f368"; }
.remixicon-share-forward-2-fill:before { content: "\f369"; }
.remixicon-share-forward-2-line:before { content: "\f36a"; }
.remixicon-share-forward-box-fill:before { content: "\f36b"; }
.remixicon-share-forward-box-line:before { content: "\f36c"; }
.remixicon-share-forward-fill:before { content: "\f36d"; }
.remixicon-share-forward-line:before { content: "\f36e"; }
.remixicon-share-line:before { content: "\f36f"; }
.remixicon-shield-cross-fill:before { content: "\f370"; }
.remixicon-shield-cross-line:before { content: "\f371"; }
.remixicon-shield-fill:before { content: "\f372"; }
.remixicon-shield-flash-fill:before { content: "\f373"; }
.remixicon-shield-flash-line:before { content: "\f374"; }
.remixicon-shield-line:before { content: "\f375"; }
.remixicon-ship-2-fill:before { content: "\f376"; }
.remixicon-ship-2-line:before { content: "\f377"; }
.remixicon-ship-fill:before { content: "\f378"; }
.remixicon-ship-line:before { content: "\f379"; }
.remixicon-shopping-bag-2-fill:before { content: "\f37a"; }
.remixicon-shopping-bag-2-line:before { content: "\f37b"; }
.remixicon-shopping-bag-3-fill:before { content: "\f37c"; }
.remixicon-shopping-bag-3-line:before { content: "\f37d"; }
.remixicon-shopping-bag-fill:before { content: "\f37e"; }
.remixicon-shopping-bag-line:before { content: "\f37f"; }
.remixicon-shopping-cart-2-fill:before { content: "\f380"; }
.remixicon-shopping-cart-2-line:before { content: "\f381"; }
.remixicon-shopping-cart-fill:before { content: "\f382"; }
.remixicon-shopping-cart-line:before { content: "\f383"; }
.remixicon-shuffle-fill:before { content: "\f384"; }
.remixicon-shuffle-line:before { content: "\f385"; }
.remixicon-shut-down-fill:before { content: "\f386"; }
.remixicon-shut-down-line:before { content: "\f387"; }
.remixicon-side-bar-fill:before { content: "\f388"; }
.remixicon-side-bar-line:before { content: "\f389"; }
.remixicon-signal-tower-fill:before { content: "\f38a"; }
.remixicon-signal-tower-line:before { content: "\f38b"; }
.remixicon-sim-card-2-fill:before { content: "\f38c"; }
.remixicon-sim-card-2-line:before { content: "\f38d"; }
.remixicon-sim-card-fill:before { content: "\f38e"; }
.remixicon-sim-card-line:before { content: "\f38f"; }
.remixicon-sip-fill:before { content: "\f390"; }
.remixicon-sip-line:before { content: "\f391"; }
.remixicon-skip-back-fill:before { content: "\f392"; }
.remixicon-skip-back-line:before { content: "\f393"; }
.remixicon-skip-back-mini-fill:before { content: "\f394"; }
.remixicon-skip-back-mini-line:before { content: "\f395"; }
.remixicon-skip-forward-fill:before { content: "\f396"; }
.remixicon-skip-forward-line:before { content: "\f397"; }
.remixicon-skip-forward-mini-fill:before { content: "\f398"; }
.remixicon-skip-forward-mini-line:before { content: "\f399"; }
.remixicon-skype-fill:before { content: "\f39a"; }
.remixicon-skype-line:before { content: "\f39b"; }
.remixicon-slack-fill:before { content: "\f39c"; }
.remixicon-slack-line:before { content: "\f39d"; }
.remixicon-slideshow-2-fill:before { content: "\f39e"; }
.remixicon-slideshow-2-line:before { content: "\f39f"; }
.remixicon-slideshow-3-fill:before { content: "\f3a0"; }
.remixicon-slideshow-3-line:before { content: "\f3a1"; }
.remixicon-slideshow-4-fill:before { content: "\f3a2"; }
.remixicon-slideshow-4-line:before { content: "\f3a3"; }
.remixicon-slideshow-fill:before { content: "\f3a4"; }
.remixicon-slideshow-line:before { content: "\f3a5"; }
.remixicon-smartphone-fill:before { content: "\f3a6"; }
.remixicon-smartphone-line:before { content: "\f3a7"; }
.remixicon-snapchat-fill:before { content: "\f3a8"; }
.remixicon-snapchat-line:before { content: "\f3a9"; }
.remixicon-sound-module-fill:before { content: "\f3aa"; }
.remixicon-sound-module-line:before { content: "\f3ab"; }
.remixicon-space-ship-fill:before { content: "\f3ac"; }
.remixicon-space-ship-line:before { content: "\f3ad"; }
.remixicon-space:before { content: "\f3ae"; }
.remixicon-speaker-2-fill:before { content: "\f3af"; }
.remixicon-speaker-2-line:before { content: "\f3b0"; }
.remixicon-speaker-3-fill:before { content: "\f3b1"; }
.remixicon-speaker-3-line:before { content: "\f3b2"; }
.remixicon-speaker-fill:before { content: "\f3b3"; }
.remixicon-speaker-line:before { content: "\f3b4"; }
.remixicon-speed-fill:before { content: "\f3b5"; }
.remixicon-speed-line:before { content: "\f3b6"; }
.remixicon-speed-mini-fill:before { content: "\f3b7"; }
.remixicon-speed-mini-line:before { content: "\f3b8"; }
.remixicon-stack-fill:before { content: "\f3b9"; }
.remixicon-stack-line:before { content: "\f3ba"; }
.remixicon-stack-overflow-fill:before { content: "\f3bb"; }
.remixicon-stack-overflow-line:before { content: "\f3bc"; }
.remixicon-star-fill:before { content: "\f3bd"; }
.remixicon-star-line:before { content: "\f3be"; }
.remixicon-star-s-fill:before { content: "\f3bf"; }
.remixicon-star-s-line:before { content: "\f3c0"; }
.remixicon-stop-circle-fill:before { content: "\f3c1"; }
.remixicon-stop-circle-line:before { content: "\f3c2"; }
.remixicon-stop-fill:before { content: "\f3c3"; }
.remixicon-stop-line:before { content: "\f3c4"; }
.remixicon-stop-mini-fill:before { content: "\f3c5"; }
.remixicon-stop-mini-line:before { content: "\f3c6"; }
.remixicon-store-2-fill:before { content: "\f3c7"; }
.remixicon-store-2-line:before { content: "\f3c8"; }
.remixicon-store-3-fill:before { content: "\f3c9"; }
.remixicon-store-3-line:before { content: "\f3ca"; }
.remixicon-store-fill:before { content: "\f3cb"; }
.remixicon-store-line:before { content: "\f3cc"; }
.remixicon-subtract-fill:before { content: "\f3cd"; }
.remixicon-subtract-line:before { content: "\f3ce"; }
.remixicon-subway-fill:before { content: "\f3cf"; }
.remixicon-subway-line:before { content: "\f3d0"; }
.remixicon-swap-fill:before { content: "\f3d1"; }
.remixicon-swap-line:before { content: "\f3d2"; }
.remixicon-t-box-fill:before { content: "\f3d3"; }
.remixicon-t-box-line:before { content: "\f3d4"; }
.remixicon-table-fill:before { content: "\f3d5"; }
.remixicon-table-line:before { content: "\f3d6"; }
.remixicon-tablet-fill:before { content: "\f3d7"; }
.remixicon-tablet-line:before { content: "\f3d8"; }
.remixicon-taobao-fill:before { content: "\f3d9"; }
.remixicon-taobao-line:before { content: "\f3da"; }
.remixicon-tape-fill:before { content: "\f3db"; }
.remixicon-tape-line:before { content: "\f3dc"; }
.remixicon-task-fill:before { content: "\f3dd"; }
.remixicon-task-line:before { content: "\f3de"; }
.remixicon-taxi-fill:before { content: "\f3df"; }
.remixicon-taxi-line:before { content: "\f3e0"; }
.remixicon-telegram-fill:before { content: "\f3e1"; }
.remixicon-telegram-line:before { content: "\f3e2"; }
.remixicon-terminal-box-fill:before { content: "\f3e3"; }
.remixicon-terminal-box-line:before { content: "\f3e4"; }
.remixicon-terminal-fill:before { content: "\f3e5"; }
.remixicon-terminal-line:before { content: "\f3e6"; }
.remixicon-text:before { content: "\f3e7"; }
.remixicon-thumb-down-fill:before { content: "\f3e8"; }
.remixicon-thumb-down-line:before { content: "\f3e9"; }
.remixicon-thumb-up-fill:before { content: "\f3ea"; }
.remixicon-thumb-up-line:before { content: "\f3eb"; }
.remixicon-time-fill:before { content: "\f3ec"; }
.remixicon-time-line:before { content: "\f3ed"; }
.remixicon-timer-2-fill:before { content: "\f3ee"; }
.remixicon-timer-2-line:before { content: "\f3ef"; }
.remixicon-timer-fill:before { content: "\f3f0"; }
.remixicon-timer-line:before { content: "\f3f1"; }
.remixicon-todo-fill:before { content: "\f3f2"; }
.remixicon-todo-line:before { content: "\f3f3"; }
.remixicon-traffic-light-fill:before { content: "\f3f4"; }
.remixicon-traffic-light-line:before { content: "\f3f5"; }
.remixicon-train-fill:before { content: "\f3f6"; }
.remixicon-train-line:before { content: "\f3f7"; }
.remixicon-treasure-map-fill:before { content: "\f3f8"; }
.remixicon-treasure-map-line:before { content: "\f3f9"; }
.remixicon-trello-fill:before { content: "\f3fa"; }
.remixicon-trello-line:before { content: "\f3fb"; }
.remixicon-trophy-fill:before { content: "\f3fc"; }
.remixicon-trophy-line:before { content: "\f3fd"; }
.remixicon-truck-fill:before { content: "\f3fe"; }
.remixicon-truck-line:before { content: "\f3ff"; }
.remixicon-tumblr-fill:before { content: "\f400"; }
.remixicon-tumblr-line:before { content: "\f401"; }
.remixicon-tv-2-fill:before { content: "\f402"; }
.remixicon-tv-2-line:before { content: "\f403"; }
.remixicon-tv-fill:before { content: "\f404"; }
.remixicon-tv-line:before { content: "\f405"; }
.remixicon-twitter-fill:before { content: "\f406"; }
.remixicon-twitter-line:before { content: "\f407"; }
.remixicon-u-disk-fill:before { content: "\f408"; }
.remixicon-u-disk-line:before { content: "\f409"; }
.remixicon-underline:before { content: "\f40a"; }
.remixicon-upload-2-fill:before { content: "\f40b"; }
.remixicon-upload-2-line:before { content: "\f40c"; }
.remixicon-upload-cloud-fill:before { content: "\f40d"; }
.remixicon-upload-cloud-line:before { content: "\f40e"; }
.remixicon-upload-fill:before { content: "\f40f"; }
.remixicon-upload-line:before { content: "\f410"; }
.remixicon-user-2-fill:before { content: "\f411"; }
.remixicon-user-2-line:before { content: "\f412"; }
.remixicon-user-3-fill:before { content: "\f413"; }
.remixicon-user-3-line:before { content: "\f414"; }
.remixicon-user-4-fill:before { content: "\f415"; }
.remixicon-user-4-line:before { content: "\f416"; }
.remixicon-user-5-fill:before { content: "\f417"; }
.remixicon-user-5-line:before { content: "\f418"; }
.remixicon-user-add-fill:before { content: "\f419"; }
.remixicon-user-add-line:before { content: "\f41a"; }
.remixicon-user-fill:before { content: "\f41b"; }
.remixicon-user-follow-fill:before { content: "\f41c"; }
.remixicon-user-follow-line:before { content: "\f41d"; }
.remixicon-user-line:before { content: "\f41e"; }
.remixicon-user-location-fill:before { content: "\f41f"; }
.remixicon-user-location-line:before { content: "\f420"; }
.remixicon-user-search-fill:before { content: "\f421"; }
.remixicon-user-search-line:before { content: "\f422"; }
.remixicon-user-settings-fill:before { content: "\f423"; }
.remixicon-user-settings-line:before { content: "\f424"; }
.remixicon-user-smile-fill:before { content: "\f425"; }
.remixicon-user-smile-line:before { content: "\f426"; }
.remixicon-user-star-fill:before { content: "\f427"; }
.remixicon-user-star-line:before { content: "\f428"; }
.remixicon-user-unfollow-fill:before { content: "\f429"; }
.remixicon-user-unfollow-line:before { content: "\f42a"; }
.remixicon-user-voice-fill:before { content: "\f42b"; }
.remixicon-user-voice-line:before { content: "\f42c"; }
.remixicon-video-fill:before { content: "\f42d"; }
.remixicon-video-line:before { content: "\f42e"; }
.remixicon-vidicon-2-fill:before { content: "\f42f"; }
.remixicon-vidicon-2-line:before { content: "\f430"; }
.remixicon-vidicon-fill:before { content: "\f431"; }
.remixicon-vidicon-line:before { content: "\f432"; }
.remixicon-vip-crown-2-fill:before { content: "\f433"; }
.remixicon-vip-crown-2-line:before { content: "\f434"; }
.remixicon-vip-crown-fill:before { content: "\f435"; }
.remixicon-vip-crown-line:before { content: "\f436"; }
.remixicon-vip-diamond-fill:before { content: "\f437"; }
.remixicon-vip-diamond-line:before { content: "\f438"; }
.remixicon-vip-fill:before { content: "\f439"; }
.remixicon-vip-line:before { content: "\f43a"; }
.remixicon-volume-down-fill:before { content: "\f43b"; }
.remixicon-volume-down-line:before { content: "\f43c"; }
.remixicon-volume-mute-fill:before { content: "\f43d"; }
.remixicon-volume-mute-line:before { content: "\f43e"; }
.remixicon-volume-up-fill:before { content: "\f43f"; }
.remixicon-volume-up-line:before { content: "\f440"; }
.remixicon-walk-fill:before { content: "\f441"; }
.remixicon-walk-line:before { content: "\f442"; }
.remixicon-wallet-2-fill:before { content: "\f443"; }
.remixicon-wallet-2-line:before { content: "\f444"; }
.remixicon-wallet-3-fill:before { content: "\f445"; }
.remixicon-wallet-3-line:before { content: "\f446"; }
.remixicon-wallet-fill:before { content: "\f447"; }
.remixicon-wallet-line:before { content: "\f448"; }
.remixicon-webcam-fill:before { content: "\f449"; }
.remixicon-webcam-line:before { content: "\f44a"; }
.remixicon-wechat-2-fill:before { content: "\f44b"; }
.remixicon-wechat-2-line:before { content: "\f44c"; }
.remixicon-wechat-fill:before { content: "\f44d"; }
.remixicon-wechat-line:before { content: "\f44e"; }
.remixicon-wechat-pay-fill:before { content: "\f44f"; }
.remixicon-wechat-pay-line:before { content: "\f450"; }
.remixicon-weibo-fill:before { content: "\f451"; }
.remixicon-weibo-line:before { content: "\f452"; }
.remixicon-whatsapp-fill:before { content: "\f453"; }
.remixicon-whatsapp-line:before { content: "\f454"; }
.remixicon-wifi-fill:before { content: "\f455"; }
.remixicon-wifi-line:before { content: "\f456"; }
.remixicon-window-2-fill:before { content: "\f457"; }
.remixicon-window-2-line:before { content: "\f458"; }
.remixicon-window-fill:before { content: "\f459"; }
.remixicon-window-line:before { content: "\f45a"; }
.remixicon-windows-fill:before { content: "\f45b"; }
.remixicon-windows-line:before { content: "\f45c"; }
.remixicon-women-fill:before { content: "\f45d"; }
.remixicon-women-line:before { content: "\f45e"; }
.remixicon-youtube-fill:before { content: "\f45f"; }
.remixicon-youtube-line:before { content: "\f460"; }
.remixicon-zcool-fill:before { content: "\f461"; }
.remixicon-zcool-line:before { content: "\f462"; }
.remixicon-zhihu-fill:before { content: "\f463"; }
.remixicon-zhihu-line:before { content: "\f464"; }
.remixicon-zoom-in-fill:before { content: "\f465"; }
.remixicon-zoom-in-line:before { content: "\f466"; }
.remixicon-zoom-out-fill:before { content: "\f467"; }
.remixicon-zoom-out-line:before { content: "\f468"; }
