.profile-container .profile-header2 {}

.profile-container .profile-header2 .profile-info2 {}

.profile-container .profile-header2 .profile-info2 .header-fullname {
    margin-top: 5px;
    display: inline-block;
}

.profile-container .profile-header2 .profile-info2 .header-information {
    line-height: 23px;
}

.profile-container .profile-header2 .profile-stats2 .stats-col {
    margin: 30px 0;
    text-align: center;
}

.profile-container .profile-header2 .profile-stats2 .stats-col .stats-value {
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 30px;
    font-family: 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
}

.header-avatar {
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    border: 2px solid #f5f5f5;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.font-12 {
    font-size: 12px;
}

.font-24 {
    font-size: 18px;
}


.navbar .navbar-inner .navbar-header .navbar-account .account-area {
    right: 10px !important;
}

.bg-whitesmoke {
    background-color: whitesmoke;
}

.pinke {
    background-color: #FDEAF3;
}

.dropdownwidth {
    width: 350px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-2 {
    margin-top: 2px !important;
}

.margin-top-3 {
    margin-top: 3px !important;
}

.margin-left-n2 {
    margin-left: -2px !important;
}

.RedCustom {
    padding: 1px 3px;
    border-radius: 3px;
    color: white;
    background-color: rgba(183, 19, 19, 0.75);
}

.BlueCustom {
    padding: 1px 3px;
    border-radius: 3px;
    color: white;
    background-color: rgba(20, 69, 242, 0.75);
}

.YellowCustom {
    padding: 1px 3px;
    border-radius: 3px;
    color: white;
    background-color: rgba(232, 205, 12, 0.75);
}

.OrangeCustom {
    padding: 1px 3px;
    border-radius: 3px;
    color: white;
    background-color: rgba(240, 114, 28, 0.75);
}

.GreenCustom {
    padding: 1px 3px;
    border-radius: 3px;
    color: white;
    background-color: rgba(51, 197, 61, 0.75);
}

.PaleYellowCustom {
    padding: 1px 3px;
    border-radius: 3px;
    color: white;
    background-color: rgb(136, 148, 88);
}

.textarea_noresize {
    resize: none;
}

.footable-edit {
    background-color: #fbfbfb !important;
    border-color: green;
}

.footable-delete {
    background-color: #fbfbfb !important;
    border-color: red;
}

.hidden {
    display: none;
}

.mytable-col {
    padding: 5px;
    vertical-align: top;
}

.page-breadcrumbs {
    background: #EDEDED !important;
}

.custom-widget-border .widget-header {
    background: #FFFFFF;
}

.custom-widget-border .bordered-darkorange {
    border-color: #737373 !important;
}

.custom-widget-border .widget-body {
    background-color: rgb(237, 237, 237) !important;
}

.loginbox-textbox .blue {
    color: #444444 !important;
}

.loginbox-title {
    font-family: 'Open Sans', 'Segoe UI' !important;
    font-weight: 600 !important;
}

.font-10px {
    font-size: 10px !important;
}

.logo-img {
    height: 30px !important;
    width: 30px !important;
}

.logo-img-login {
    height: 60px !important;
    width: 60px !important;
}

.logo-title-img {
    padding: 8px 0 2px 0 !important;
}

.page-sidebar .sidebar-info {
    position: relative;
    z-index: 124;
    background: #f3f3f3;
}

.menu-compact .sidebar-info {
    display: none;
}

.sidebar-info #widget-profileinfo.widget1 {
    overflow: hidden;
}

.sidebar-info .widget1:not(.stay-on-collapse) {
    transition-duration: 300ms;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: top left;
    transform-origin: top left;
}

.sidebar-info .widget1 .widget1-body .userinfo {
    padding: 10px 0px 24px 5px;
    position: relative;
    background-size: 240px 128px;
    background-color: rgb(255, 255, 255);
}

.avatar {
    width: 40px;
    height: 40px;
}

.sidebar-info .widget1 .widget1-body .userinfo .avatar {
    height: 50px;
    width: 50px;
    margin: 0 auto;
}

.img-circle {
    border-radius: 50%;
}

.img-responsive,
.thumbnail>img,
.thumbnail a>img,
.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    display: block;
    max-width: 75%;
    height: auto;
}

img {
    vertical-align: middle;
}

img {
    border: 0;
}

.sidebar-info .widget1 .widget1-body .userinfo .info {
    padding: 8px 0 8px 0;
}

.sidebar-info .widget1 .widget1-body .userinfo .info .username {
    font-size: 18px !important;
    line-height: 24px;
    display: block;
    text-align: left;
    padding-left: 64px;
    color: rgb(115, 115, 115);
}

.sidebar-info .widget1 .widget1-body .userinfo .info .useremail {
    font-size: 11px;
    line-height: 1.35;
    display: block;
    text-align: center;
    text-align: left;
    padding-left: 64px;
    color: rgb(115, 115, 115);
}

.useremail {
    padding-left: 69px !important;
}

.sidebar-info .widget1 .widget1-body .userinfo .acct-dropdown {
    bottom: 0;
    background: rgba(45, 195, 232, 0.8);
    width: 100%;
    left: 0;
    padding: 6px;
    color: rgba(255, 255, 255, 0.87);
    font-size: 11px;
}

.huge {
    font-size: 40px;
    line-height: normal;
}

.panel-default>.panel-heading {
    background-color: #FFFFFF;
}

.panel-default a,
.panel-default>.panel-heading {
    color: #777777;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #5cb85c;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.panel-yellow a {
    color: #f0ad4e;
}

.panel-red {
    border-color: rgba(217, 83, 79, 0.92);
}

.panel-red .panel-heading {
    border-color: rgba(217, 83, 79, 0.92);
    color: #fff;
    background-color: rgba(217, 83, 79, 0.92);
}

.panel-red a {
    color: rgba(217, 83, 79, 0.92);
}

.panel-blue {
    border-color: #2DABA9;
}

.panel-blue .panel-heading {
    border-color: #2DABA9;
    color: #fff;
    background-color: #2DABA9;
}

.panel-blue a {
    color: #2DABA9;
}

.panel-purple {
    border-color: #881BD0;
}

.panel-purple .panel-heading {
    border-color: #881BD0;
    color: #fff;
    background-color: #881BD0;
}

.panel-purple a {
    color: #881BD0;
}

.panel-blueberry {
    border-color: #6f85bf;
}

.panel-blueberry .panel-heading {
    border-color: #6f85bf;
    color: #fff;
    background-color: #6f85bf;
}

.panel-blueberry a {
    color: #6f85bf;
}

.panel-lightpurple {
    border-color: #a7a8cc;
}

.panel-lightpurple .panel-heading {
    border-color: #a7a8cc;
    color: #fff;
    background-color: #a7a8cc;
}

.panel-lightpurple a {
    color: #a7a8cc;
}

.panel-lightblue {
    border-color: rgb(27, 177, 208);
}

.panel-lightblue .panel-heading {
    border-color: rgb(27, 177, 208);
    color: #fff;
    background-color: rgb(27, 177, 208);
}

.panel-lightblue a {
    color: rgb(27, 177, 208);
}

.panel-disabled {
    border-color: rgb(210, 210, 210);
}

.panel-disabled .panel-heading {
    border-color: rgb(210, 210, 210);
    color: #fff;
    background-color: rgb(210, 210, 210);
}

.panel-disabled a {
    color: rgb(210, 210, 210);
}

.panel-darkblue {
    border-color: #337ab7;
}

.panel-darkblue .panel-heading {
    border-color: #337ab7;
    color: #fff;
    background-color: #337ab7;
}

.panel-darkblue a {
    color: #337ab7;
}

.panel-orange {
    border-color: #ffab65;
}

.panel-orange .panel-heading {
    border-color: #ffab65;
    color: #fff;
    background-color: #ffab65;
}

.panel-orange a {
    color: #ffab65;
}

.panel-purplish {
    border-color: #a271b7;
}

.panel-purplish .panel-heading {
    border-color: #a271b7;
    color: #fff;
    background-color: #a271b7;
}

.panel-purplish a {
    color: #a271b7;
}

.child_node .dd-handle {
    padding: 0px;
    margin: 10px 0;
}

.dd {
    max-width: 100%;
}

.dd-custom li img {
    width: 48px;
}

.dd-custom li h5 {
    color: #000000;
    font-weight: 500 !important;
}

.child_node,
.child_node:hover,
.child_node:focus {
    text-decoration: none;
}

.dd-custom:hover,
.dd-custom:focus {
    text-decoration: none;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    background-color: #C3C3C3 !important;
}

.dd-custom li {
    display: inline-block !important;
    list-style-type: none !important;
}

.dd-custom {
    padding: 0px;
}

.dd-custom-image img,
.dd-custom-title h5 {
    margin: 6px 12px;
}

.dd-custom-image img {}

.form-group input {
    border-radius: 4px !important;
}

.ps-size {
    font-size: 15px;
}

.profile-container .profile-header2 {}

.profile-container .profile-header2 .profile-info2 {}

.profile-container .profile-header2 .profile-info2 .header-fullname {
    margin-top: 5px;
    display: inline-block;
}

.profile-container .profile-header2 .profile-info2 .header-information {
    line-height: 23px;
}

.profile-container .profile-header2 .profile-stats2 .stats-col {
    margin: 30px 0;
    text-align: center;
}

.profile-container .profile-header2 .profile-stats2 .stats-col .stats-value {
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 30px;
    font-family: 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
}

.profile-container .profile-header2 .header-avatar {
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    border: 2px solid #f5f5f5;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.patient-found {
    margin-bottom: 5px;
}

.text-bold {
    font-weight: 600;
}

.btn-outline {
    color: inherit;
    background-color: transparent !important;
    transition: all .5s;
    margin: 0 3px 3px 3px !important;
}

.btn-primary-custom.btn-outline {
    color: #428bca;
    border-color: #428bca;
    background-color: transparent;
}

.btn-primary.btn-outline {
    color: #428bca !important;
    border-color: #428bca !important;
    background-color: transparent !important;
}

.btn-primary-custom.btn-outline:hover,
.btn-primary-custom.btn-outline:focus {
    background-color: #428bca !important;
    border-color: #428bca !important;
    color: #fff !important;
}

.btn-red-custom.btn-outline {
    color: #d73d32;
    border-color: #d73d32;
    background-color: transparent;
}

.btn-red-custom.btn-outline:hover,
.btn-red-custom.btn-outline:focus {
    background-color: #d73d32 !important;
    border-color: #d73d32 !important;
    color: #fff !important;
}

.btn-sky-custom.btn-outline {
    color: #11a9cc;
    border-color: #11a9cc;
    background-color: transparent;
}

.btn-sky-custom.btn-outline:hover,
.btn-sky-custom.btn-outline:focus {
    background-color: #11a9cc !important;
    border-color: #11a9cc !important;
    color: #fff !important;
}

.btn-green-custom.btn-outline {
    color: #53a93f;
    border-color: #53a93f;
    background-color: transparent;
}

.btn-green-custom.btn-outline:hover,
.btn-green-custom.btn-outline:focus {
    background-color: #53a93f !important;
    border-color: #53a93f !important;
    color: #fff !important;
}

.btn-info.btn-outline {
    color: #5bc0de !important;
}

.btn-danger.btn-outline {
    color: #d9534f !important;
}

.btn-orange.btn-outline {
    color: #ed4e2a !important;
    border-color: #ed4e2a !important;
}

.btn-orange.btn-outline:hover,
.btn-orange.btn-outline:focus {
    background-color: #ed4e2a !important;
    border-color: #ed4e2a !important;
    color: #fff !important;
}

.btn-success2.btn-outline {
    color: #5cb85c !important;
    border-color: #5cb85c !important;
}

.btn-success2.btn-outline:hover,
.btn-success2.btn-outline:focus {
    background-color: #5cb85c !important;
    border-color: #5cb85c !important;
    color: #fff !important;
}

.btn-primary2.btn-outline {
    color: #428bca !important;
    border-color: #428bca !important;
}

.btn-primary2.btn-outline:hover,
.btn-primary2.btn-outline:focus {
    background-color: #428bca !important;
    border-color: #428bca !important;
    color: #fff !important;
}

.btn-blueberry2.btn-outline {
    color: #a7a8cc !important;
    border-color: #a7a8cc !important;
}

.btn-blueberry2.btn-outline:hover,
.btn-blueberry2.btn-outline:focus {
    background-color: #a7a8cc !important;
    border-color: #a7a8cc !important;
    color: #fff !important;
}

.btn-warning2.btn-outline {
    color: #f0ad4e !important;
    border-color: #f0ad4e !important;
}

.btn-warning2.btn-outline:hover,
.btn-warning2.btn-outline:focus {
    background-color: #f0ad4e !important;
    border-color: #f0ad4e !important;
    color: #fff !important;
}

.btn-info2.btn-outline {
    color: #5bc0de !important;
    border-color: #5bc0de !important;
}

.btn-info2.btn-outline:hover,
.btn-info2.btn-outline:focus {
    background-color: #5bc0de !important;
    border-color: #5bc0de !important;
    color: #fff !important;
}

.btn-default2.btn-outline {
    color: #333 !important;
    border-color: #CCC !important;
}

.btn-default2.btn-outline:hover,
.btn-default2.btn-outline:focus {
    color: #333 !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
    background-color: transparent;
}

.custom-widget {
    min-height: 55px;
    padding-top: 10px;
}

.custom-widget-padding-top {
    padding-top: 1px !important;
}

.custom-widget-padding-bottom {
    padding-bottom: 7px !important;
}

.custom-widget-body .row {
    margin: 5px !important;
}

.custom-widget-buttons {
    text-align: right;
}

.custom-fa {
    margin-right: 0px !important;
    font-size: 18px !important;
}

.form-group-custom {
    margin-bottom: 5px !important;
}

.btn-custom {
    margin-right: 5px;
}

.theme-color-custom {
    color: #ffffff;
    font-weight: 500;
}

.custom-table {
    font-weight: 500;
    color: rgba(255, 255, 255, 1) !important;
}

.custom-table-foot {
    border: 1px solid #dddddd !important;
}

.filter-custom input[type="text"] {
    width: 150px;
    height: 25px;
    border-radius: 4px !important;
}

#largeModal .modal-lg {
    height: 98vh;
    width: 90%;
    margin-top: 0px;
    margin-bottom: 0px;
}

#largeModal .modal-lg2 {
    width: inherit !important;
}

#largeModal .modal-content {
    height: 100%;
}

#largeModal .modal-body {
    height: 90%;
}

.flex-direction-nav a {
    height: 43px !important;
    margin: 0px !important;
    top: 100% !important;
}

.flex-control-paging li a {
    display: initial !important;
    background: #fff !important;
    moz-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3) !important;
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3) !important;
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3) !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    border-radius: 2px !important;
    color: black !important;
    font-size: 16px !important;
    padding-right: 5px;
    padding-left: 5px;
}

.flex-control-paging li a.flex-active {
    background: #000 !important;
    background: rgba(0, 0, 0, 0.9) !important;
    color: white !important;
}

.info-custom {
    color: rgba(82, 82, 82, 0.83);
    font-size: 14px;
}

.info-custom-inner {
    color: #444444;
}

.custom-spacing {
    padding: 7px;
    background-color: #FAFAFA;
}

.custom-spacing2 {
    padding: 0px;
}

.patient-details-modal {
    display: inline-block;
    margin-top: 5px;
    width: 100%;
}

.nav-tabs.tabs-flat {
    background-color: rgb(250, 250, 250);
}

.custom-spacing .tab-content {
    padding: 3px 0px 0px 0px !important;
}

.widget-header>.widget-caption {
    font-size: 15px;
}

.page-header .header-title h1 {
    font-weight: 400 !important;
}

.padding-custom {
    margin-top: 5px !important;
}

.padding-custom-right {
    padding-right: 10px;
}

.padding-none-custom {
    padding: 0px;
}

.table thead>tr>th {
    font-size: 14px;
    vertical-align: top;
}

.modal-title {
    margin: 0px !important;
    color: #fff;
}

.custom-table-padding .footable-row-detail-value {
    padding-left: 30px;
}

.custom-table-padding .footable-detail-show p span {
    font-family: "Open Sans", Arial, Helvetica, sans-serif !important;
    color: #444444 !important;
}

.custom-head-hidden .footable-detail-show th {
    display: none !important;
}

.custom-table-span-padding {
    padding-left: 25px;
}

.row-custom {
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.status-metro {
    display: inline-block;
    padding: 2px 5px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.status-metro.status-active {
    background: #A1CC9E;
    color: #4F4944;
}

.status-metro.status-yellow {
    background: rgba(222, 134, 24, 0.6);
    color: #FFFFFF;
    font-size: 13px !important;
}

.status-metro.status-disabled {
    background: rgba(222, 134, 24, 0.6);
    color: #FFFFFF;
    font-size: 13px !important;
}

.status-metro.status-suspended {
    background: #ED9999;
    color: #4F4944;
}

.status-metro.status-blue {
    background: rgba(58, 139, 212, 0.68);
    color: #FFFFFF;
    font-size: 13px !important;
}

.status-metro.status-green {
    background: rgb(104, 171, 109);
    color: #FFFFFF;
    font-size: 13px !important;
}

.footable-filtering>th,
.footable-filtering>td,
.table-bordered thead>tr {
    border: 0px !important;
    background-color: #FFFFFF !important;
    background-image: none !important;
}

.footable-filtering .checkbox input[type=checkbox],
.footable-filtering .checkbox input[type=radio] {
    opacity: 1;
    left: 30px;
}

.table-show-custom .table-row-custom {
    display: table-cell !important;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    padding-right: 15px;
    padding-left: 15px;
}

table.footable>tbody>tr.footable-empty>td {
    font-size: 20px !important;
}

.custom-width-tooltip .popoverexample .popover {
    width: auto !important;
    margin: 0px !important;
}

.dropdown-footer div {
    padding-bottom: 10px !important;
}

.dropdown-footer div a {
    text-decoration: none !important;
}

.center {
    text-align: center !important;
}

@media only screen and (max-width: 1200px) {
    .custom-widget {
        min-height: 150px;
    }

    .centermodal {
        left: 25% !important;
        right: 25% !important;
        top: 20% !important;
    }

    .centercacmodal {
        left: 25% !important;
        right: 25% !important;
        top: 20% !important;
    }
}

@media only screen and (max-width: 400px) {
    .centercacmodal {
        left: 25% !important;
        right: 25% !important;
        top: 20% !important;
    }

    .centermodal {
        left: 0 !important;
        right: 0 !important;
        top: 20% !important;
        margin-left: -200px;
    }

    .custom-margin-top {
        margin-top: 35px;
        margin-bottom: 5px;
    }

    .custom-padding-rl {
        padding-left: 20px;
        padding-right: 20px;
    }

    .tbl-row-btnview {
        padding: 0px 10px 5px 15px !important;
    }

    .tbl-btnview {
        margin-top: 0px !important;
    }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
    .centercacmodal {
        left: 5% !important;
        right: 5% !important;
        top: 30% !important;
    }

    .centermodal {
        left: 0 !important;
        right: 0 !important;
        top: 20% !important;
        margin-left: -100px;
    }

    .custom-margin-top {
        margin-top: 55px;
        margin-bottom: 43px;
    }

    .custom-padding-rl {
        padding-left: 60px;
        padding-right: 60px;
    }

    .custom-margin-top {
        margin-top: 35px;
        margin-bottom: 5px;
    }

    .custom-padding-rl {
        padding-left: 20px;
        padding-right: 20px;
    }

    .tbl-row-btnview {
        padding: 0px 10px 10px 15px !important;
    }

    .tbl-btnview {
        margin-top: 0px !important;
    }

    .custom-margin-top-request {
        margin-top: 0px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 750px) {
    .centercacmodal {
        left: 25% !important;
        right: 25% !important;
        top: 20% !important;
    }

    .centermodal {
        left: 25% !important;
        right: 5% !important;
        top: 25% !important;
    }

    .custom-margin-top {
        margin-top: 55px;
        margin-bottom: 43px;
    }

    .custom-padding-rl {
        padding-left: 70px;
        padding-right: 70px;
    }

    .tbl-row-btnview {
        padding: 0px 10px 10px 15px !important;
    }

    .tbl-btnview {
        margin-top: 0px !important;
    }

    .custom-margin-top-request {
        margin-top: 0px !important;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1000px) {
    .centercacmodal {
        left: 25% !important;
        right: 25% !important;
        top: 20% !important;
    }

    .centermodal {
        left: 25% !important;
        right: 25% !important;
        top: 35% !important;
    }

    .custom-margin-top {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .custom-padding-rl {
        padding-left: 10px;
        padding-right: 10px;
    }

    .tbl-row-btnview {
        padding: 0px 10px 0px 10px !important;
    }

    .tbl-btnview {
        margin-top: -48px !important;
    }

    .custom-margin-top-request {
        margin-top: 20px !important;
    }
}

@media only screen and (min-width: 1000px) {
    .centercacmodal {
        left: 25% !important;
        right: 25% !important;
        top: 20% !important;
    }

    .centermodal {
        left: 25% !important;
        right: 25% !important;
        top: 40% !important;
    }

    .custom-margin-top {
        margin-top: 55px;
        margin-bottom: 45px;
    }

    .custom-padding-rl {
        padding-left: 80px;
        padding-right: 80px;
    }

    .tbl-row-btnview {
        padding: 0px 10px 0px 10px !important;
    }

    .tbl-btnview {
        margin-top: -48px !important;
    }
}

@media only screen and (max-width: 450px) {
    .iframepref {
        height: 320px;
    }

    .jconfirm-box {
        width: 350px !important;
    }
}

@media only screen and (min-width: 1200px) {
    .iframepref {
        height: 279px;
    }

    .custom-margin-search {
        padding-right: 40px !important;
    }

    .tbl-row-btnview {
        padding: 0px 10px 0px 10px !important;
    }

    .tbl-btnview {
        margin-top: -48px !important;
    }

    .custom-margin-top-request {
        margin-top: 40px !important;
    }
}

.form-horizontal .control-label-left {
    padding-bottom: 7px;
    margin-bottom: 5px;
    text-align: left;
}

.control-label-left {
    padding-top: 7px;
    margin-bottom: 0px;
    text-align: left;
}

.control-label-readonly {
    width: 250px !important;
    border: none !important;
    background-color: transparent !important;
}

.col-lg-6-NoPadding {
    width: 50% !important;
    min-height: 1px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.right {
    text-align: right !important;
}

.left {
    text-align: left !important;
}

.widget-header .widget-caption {
    font-weight: 600 !important;
    font-size: 15px !important;
}

textarea.form-control {
    border-radius: 3px !important;
}

.well .header {
    font-weight: bold !important;
}

.well-marginless {
    margin-bottom: 8px !important;
    padding-bottom: 5px !important;
    padding-top: 55px !important;
}

.form-group {
    margin-bottom: 5px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.widget-body {
    padding: 10px !important;
}

.widget {
    margin: 0 0 12px 0 !important;
}

.less-margin {
    margin-bottom: 2px !important;
    margin-top: 2px !important;
}

.tab-margin-less {
    margin-top: 0px !important;
}

.profile-container .profile-body .tab-content {
    margin-top: 0px !important;
}

.popover-margin-less {
    margin: 1px 5px 2px 10px !important;
}

.popover-content-padding-less {
    padding: 6.5px 3px 6.5px 15px !important;
    text-align: left !important;
    font-size: 13px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #DCE1E2 !important;
    border: 1px solid #D8DDDE !important;
    color: #1F1E1E !important;
}

.text-label-captions {
    color: rgba(82, 82, 82, 0.83) !important;
}

.bold-values {
    font-weight: 900 !important;
}

.light-bold-values {
    font-weight: 600 !important;
}

.patient-search-margin {
    margin-bottom: 15px !important;
}

.margin-top-15 {
    margin-top: 15px;
}

.text-danger {
    color: red !important;
    font-size: 12px !important;
}

.tooltip-primarysb+.tooltip>.tooltip-inner {
    border: 1px solid #428bca;
    background-color: #428bca;
    color: #fff;
}

.tooltip-primarysb+.tooltip.top .tooltip-arrow {
    border-top-color: #428bca;
}

.tooltip-primarysb+.tooltip.right .tooltip-arrow {
    border-right-color: #428bca;
}

.tooltip-primarysb+.tooltip.left .tooltip-arrow {
    border-left-color: #428bca;
}

.tooltip-primarysb+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #428bca;
}

.disabled {
    background-color: #CCC !important;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: rgba(224, 224, 224, 0.57) !important;
}

.span-dropdown-addon {
    padding: 0px !important;
}

.span-dropdown-list-custom {
    padding-left: 0px !important;
    width: 36px !important;
    border: 0px !important;
    font-size: 12px !important;
    padding: 0px;
}

.checkbox-margin-custom {
    margin-top: 5px !important;
}

.custom-margin-searchbox {
    margin-bottom: -40px !important;
}

.span-dropdown-addon-text {
    font-size: 13px !important;
    padding: 3px !important;
}

.span-addon-custom-text {
    padding-left: 7px;
    padding-right: 7px;
}

.span-addon-transparent {
    background-color: white;
    border: none;
}

.datepicker td.day.disabled {
    cursor: no-drop !important;
    background-color: white !important;
    color: rgba(128, 128, 128, 0.53) !important;
}

.sm-custom-icon-btn {
    padding: 2px 5px 2px 5px !important;
}

.sm-custom-icon-btn-text {
    font-size: 15px !important;
}

.no-padding-right {
    padding-right: 0px;
}

.no-padding-left {
    padding-left: 0px;
}

.no-padding {
    padding: 0px !important;
}

.table {
    margin-bottom: 10px !important;
}

.AbnormalFlag_L {
    color: #0000ff !important;
}

.AbnormalFlag_PL {
    color: #0000ff !important;
    font-weight: bold !important;
}

.AbnormalFlag_H {
    color: #ff0000 !important;
}

.AbnormalFlag_PH {
    color: #ff0000 !important;
    font-weight: bold !important;
}

td,
th {
    vertical-align: top;
}

table {
    font-size: 14px;
}

thead>tr>th,
thead>tr>td {
    font-size: 15px !important;
}

.no-margin {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.font-11 {
    font-size: 11px !important;
}

.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area .avatar {
    border-left: none !important;
}

.custom-margin-left-10 {
    margin-left: 10px !important;
}

.close {
    float: right !important;
    font-size: 21px !important;
    font-weight: bold !important;
    line-height: 1 !important;
    color: #FFF !important;
    text-shadow: 0 1px 0 #fff !important;
    filter: alpha(opacity=20) !important;
    opacity: .8 !important;
}

.padding-actionbtn {
    padding: 3px;
}

.toast-message a span,
.toast-message label {
    padding-left: 0px !important;
}

.padding-custom-demo {
    padding: 0px 10px !important;
}

.amcharts-chart-div>a {
    display: none !important;
}

.well-marginless2 {
    padding-top: 10px !important;
}

.popover.dark.right .arrow:after {
    border-right-color: #FFFCDC;
}

.popover.dark {
    background-color: #FFFCDC !important;
}

.container-custom {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.error-container h1 {
    font-size: 50px;
    font-weight: 300;
}

.padding-top-25 {
    padding-top: 25px;
}

.error-divider-custom {
    padding: 50px 0px 10px 0px;
}

.container-custom p.description {
    color: #e5e5e5;
    font-size: 20px;
    margin: 0;
}

@media (min-width: 1200px) {
    .container-custom {
        width: 1170px !important;
    }
}

@media (min-width: 992px) {
    .container-custom {
        width: 970px !important;
    }
}

@media (min-width: 768px) {
    .container-custom {
        width: 950px !important;
    }
}

.form-control,
select {
    font-size: 13px;
    color: #858585;
    background-color: #fbfbfb;
    border: 1px solid #d5d5d5;
    padding: 6px 12px;
    border-radius: 6px !important;
}

.task-container .tasks-list .task-item .task-state {
    left: 5px !important;
}

.task-container .tasks-list .task-item .task-body {
    left: 5px !important;
}

.task-container .tasks-list .task-item .task-creator {
    left: 5px !important;
}

.padding-top-15 {
    padding-top: 15px;
}

.btn.btn-circle.btn-xs-custom {
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 5px 0;
}

.btn.btn-circle.btn-xs-custom>.fa,
.btn.btn-circle.btn-xs-custom .typcn,
.btn.btn-circle.btn-xs-custom .glyphicon,
.btn.btn-circle.btn-xs-custom>[class*="wi-"] {
    font-size: 12px;
    line-height: 14px;
}

.btn-xs-custom-icon-padding {
    padding: 3px 6px !important;
}

.btn.btn-circle.btn-sm-custom {
    width: 42px;
    height: 42px;
    padding: 6px 14px;
}

.btn.btn-circle.btn-sm-custom>.fa,
.btn.btn-circle.btn-sm-custom .typcn,
.btn.btn-circle.btn-sm-custom .glyphicon,
.btn.btn-circle.btn-sm-custom>[class*="wi-"] {
    font-size: 18px;
    line-height: 26px;
    border-radius: 50%;
}

.panel-lightpurple .panel-heading {
    border-color: #7d7fd0 !important;
    color: #fff;
    background-color: #7476b5 !important;
}

.padding-top-2 {
    padding-top: 2px !important;
}

.padding-bottom-2 {
    padding-bottom: 2px !important;
}

.padding-8 {
    padding: 8px;
}

.padding-6 {
    padding: 6px;
}

.border-left-grey {
    border-left: 1px solid #d4d4d4;
}

.border-darkgrey {
    border-color: darkgrey !important;
}

.margin-top-n5 {
    margin-top: -5px;
}

.transparent-ul {
    display: block;
    list-style-type: none;
    -webkit-margin-before: 0px;
    -webkit-margin-after: 0px;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
}

.badge-sm {
    height: 2px;
    width: 20px;
    padding: 0px 1px 2px 5px;
    margin: 1px 5px 0px 0px;
    font-size: 15px !important;
    font-weight: 100;
    vertical-align: middle;
    text-align: center;
    -webkit-border-radius: 12px !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 12px !important;
    -moz-background-clip: padding !important;
    border-radius: 12px !important;
    background-clip: padding-box !important;
    -webkit-text-shadow: none !important;
    text-shadow: none !important;
}

.padding-top-2 {
    padding-top: 2px !important;
}

.padding-bottom-2 {
    padding-bottom: 2px !important;
}

.padding-8 {
    padding: 8px;
}

.padding-6 {
    padding: 6px;
}

.border-left-grey {
    border-left: 1px solid #d4d4d4;
}

.padding-right-25 {
    padding-right: 25px !important;
}

.width-50 {
    width: 50px !important;
}

.bordered-lightgrey {
    border: 1px solid #d0d0d0 !important;
}

.bordered-darkgrey {
    border: 1px solid #a9a9a9 !important;
}

.bordered-lightblue3 {
    border: 1px solid #d0d0d0 !important;
}

.borderradius-5 {
    border-radius: 5px !important;
}

.border-bottom-lightblueberry {
    border-bottom: 1px solid #eacce3;
}

.bg-lightmagenta2 {
    background-color: #fde9f0 !important;
}

.bg-lightblue2 {
    background-color: #dbebf3 !important;
}

.bg-lightblue3 {
    background-color: #e8f9ff !important;
}

.border-all-lightblue1 {
    border-color: #bbdef9 !important;
}

.border-lightblue1 {
    border: 1px solid #b6d7ff;
}

.bg-lightgrey3 {
    background-color: #e0e0e0 !important;
}

.fontcolor-black {
    color: black !important;
}

.panel-title {
    font-weight: 500 !important;
}

.slider-handle {
    background-color: #ec0404 !important;
}

.slider-handle {
    background-color: #ec0404 !important;
    background-image: linear-gradient(to bottom, #d73d32 0%, #d73d32 100%) !important;
    box-shadow: inset 0 1px 2px rgba(177, 39, 39, 0.1) !important;
}

.slider .tooltip.top {
    margin-top: -46px !important;
}

.slider-track {
    background-image: -o-linear-gradient(top, #f5f5f5 0%, #f9f9f9 100%) !important;
    background-image: linear-gradient(to bottom, #f5f5f5 0%, #f5dcdc 100%) !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-radius: 6px !important;
}

.select2-container--default .select2-selection--single {
    border-radius: 6px !important;
}

.select2-container--default .select2-selection--multiple {
    border-radius: 6px !important;
}

.alert.alert-info {
    color: #31708f !important;
    background-color: #d9edf7 !important;
    border-color: #84d2e2 !important;
}

.alert.alert-danger {
    color: #ca4e4c !important;
    background-color: #f2dede !important;
    border-color: #dc5947 !important;
}

.alert.alert-warning {
    color: #d29b27 !important;
    background-color: rgba(247, 238, 192, 0.86) !important;
    border-color: #ffad02 !important;
}

.alert.alert-success {
    color: #3c763d !important;
    background-color: rgb(203, 230, 192) !important;
    border-color: #4ebb32 !important;
}

CDSShade {
    box-shadow: 4px 5px 5px 1px rgba(90, 116, 148, 0.27);
}

.alert {
    padding: 3px;
}

.cdsImage {
    float: left !important;
}

.CDS {
    font-size: 10px !important;
    color: #677882 !important;
    padding-right: 10px !important;
}

.alert {
    margin-bottom: 10px;
    margin-top: 0;
    color: #ffffff;
    border-width: 0;
    border-left-width: 5px;
    padding: 3px;
    border-radius: 6px !important;
}

.tooltip.bottom {
    padding: 5px 0;
    margin-top: 16px;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #9ca0a1 !important;
}

.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
    padding-right: 15px !important;
}

.slider-selection {
    background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%) !important;
    background-image: -o-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%) !important;
    background-image: linear-gradient(to bottom, rgba(156, 160, 161, 0.58) 0%, rgba(156, 160, 161, 0.6) 100%) !important;
}

@media only screen and (min-width: 992px) {
    .assDateCenter {
        float: right !important;
    }
}

.padding-bottom-5 {
    padding-bottom: 5px;
}

padding-right-5 {
    padding-right: 5px;
}

.padding-top-6 {
    padding-top: 6px !important;
}

.slider.slider-horizontal .slider-tick-label-container {
    margin-top: 16px !important;
}

.SpecialtySpec {
    height: 400px !important;
}

.texttransform-uppercase {
    text-transform: uppercase;
}

.btn.btn-circle.btn-xscustom {
    width: 40px;
    height: 37px;
    text-align: center;
    padding: 9px 5px 10px 7px;
    border-radius: 1px !important;
}

.animate-me {
    animation: 4s infinite bgcolorchange;
}

@keyframes bgcolorchange {
    0% {
        color: white;
        background: red;
    }

    25% {
        color: white;
        background: green;
    }

    50% {
        color: black;
        background: yellow;
    }

    75% {
        color: white;
        background: blue;
    }

    100% {
        color: white;
        background: orange;
    }
}

@-webkit-keyframes bgcolorchange {
    0% {
        color: white;
        background: red;
    }

    25% {
        color: white;
        background: green;
    }

    50% {
        color: black;
        background: yellow;
    }

    75% {
        color: white;
        background: blue;
    }

    100% {
        color: white;
        background: orange;
    }
}

.animate-text {
    animation: 4s infinite fontbulger;
}

@keyframes fontbulger {
    0% {
        font-size: 13px;
    }

    25% {
        font-size: 14px;
    }

    50% {
        font-size: 15px;
    }

    75% {
        font-size: 14px;
    }

    100% {
        font-size: 13px;
    }
}

@-webkit-keyframes fontbulger {
    0% {
        font-size: 13px;
    }

    25% {
        font-size: 14px;
    }

    50% {
        font-size: 15px;
    }

    75% {
        font-size: 14px;
    }

    100% {
        font-size: 13px;
    }
}

.animate-text-color {
    animation: 2s infinite fontcolorchange;
}

@keyframes fontcolorchange {
    0% {
        background-color: red;
        font-weight: 800;
    }

    25% {
        background-color: blue;
        font-weight: 800;
    }

    50% {
        background-color: orange;
        font-weight: 800;
    }

    75% {
        background-color: purple;
        font-weight: 800;
    }

    100% {
        background-color: red;
        opacity: 0.4;
        font-weight: 800;
    }
}

@-webkit-keyframes fontcolorchange {
    0% {
        background-color: red;
        font-weight: 800;
    }

    25% {
        background-color: blue;
        font-weight: 800;
    }

    50% {
        background-color: orange;
        font-weight: 800;
    }

    75% {
        background-color: purple;
        font-weight: 800;
    }

    100% {
        background-color: red;
        opacity: 0.4;
        font-weight: 800;
    }
}

.select2-dropdown select2-dropdown--below {
    margin-top: 50px;
}

.bold-5 {
    font-weight: 500 !important;
}

.bold-6 {
    font-weight: 600 !important;
}

.bold-7 {
    font-weight: 700 !important;
}

.medtable {
    border: 1px solid rgb(45, 195, 232) !important;
    color: white;
    border: 0 0 1px 0;
    font-weight: normal;
    background-color: #57b5e3;
    font-size: 14px !important;
}

.colset {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.rowset {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.bg-info3 {
    background-color: #dceff9 !important;
}

.bg-white {
    background-color: white !important;
}

.custom-header-well {
    color: #595b5d !important;
    background-color: #d9edf7 !important;
    border-color: #efd6b5 !important;
}

.focusQuestionHeader {
    color: #ffffff !important;
    background-color: #219fbe !important;
    border-color: #87d1b5 !important;
}

.focusQuestionHeader span i {
    color: #ffffff !important;
}

.modal-header {
    height: 55px;
}

.modal-footer {
    height: 55px;
}

.MuiExpansionPanelSummary-content {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.Mui-expanded {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.MuiExpansionPanelSummary-root .Mui-expanded {
    min-height: 0px !important;
}

.card-pricing-icon {
    font-size: 22px;
    background-color: rgba(59, 175, 218, .1);
    height: 60px;
    display: inline-block;
    width: 60px;
    line-height: 62px;
    border-radius: 50%;
}

.MuiToggleButtonGroup-root {}

.MuiToggleButtonGroup-root .Mui-selected {
    background-color: $primary !important;

}

.MuiToggleButtonGroup-root .Mui-selected .MuiToggleButton-label {
    color: #fff;
    text-transform: capitalize;
}

.MuiToggleButtonGroup-root .MuiToggleButton-label {
    color: $dark;
    text-transform: capitalize;
}

.MuiTab-wrapper {
    text-transform: capitalize;

}

.MuiToggleButtonGroup-grouped:not(:first-child) {
    margin-right: 10px !important;

    border-left: 1px solid !important;
    border-left-color: rgba(0, 0, 0, 0.12) !important;
    border-left-style: solid !important;
    border-left-width: 1px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.MuiToggleButtonGroup-grouped:first-child {
    margin-right: 10px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;

}

.modal-header {

    background-color: $primary;
}

.custom-table-header {
    background-color: #84d2e2 !important;
    color: $white !important
}

.ism-login {
    background: url("../../assets/images/background.jpg") !important;
    z-index: 9999;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

body.enlarged {
    min-height: 0;
}

.loginbox {
    position: relative;
    width: 400px !important;
    height: auto !important;
    padding: 0 0 20px 0;
    -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
}

.bg-white-custom {
    background-color: rgba(255, 255, 255, 0.6) !important;
}

.custom-border {
    margin-top: 100px !important;
    padding-bottom: 5px !important;
    border-radius: 3px !important;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.68) !important;
}