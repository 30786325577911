//
// Daterange.scss
//

.daterangepicker {
    font-family: $font-family-base;

    td.active,td.active:hover,.ranges li.active {
        background-color: $primary;
    }
}